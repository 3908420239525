const GENERIC_REQUEST = 'inquisit-app/INBOUND_CALL_QUEUE/GENERIC_REQUEST';
const GENERIC_FAIL = 'inquisit-app/INBOUND_CALL_QUEUE/GENERIC_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/INBOUND_CALL_QUEUE/GENERIC_SUCCESS';
const INBOUND_CALL_QUEUE_LIST_REQUEST = 'inquisit-app/INBOUND_CALL_QUEUE/INBOUND_CALL_QUEUE_LIST_REQUEST';
const INBOUND_CALL_QUEUE_LIST_COUNT_REQUEST = 'inquisit-app/INBOUND_CALL_QUEUE/INBOUND_CALL_QUEUE_LIST_COUNT_REQUEST';
const INBOUND_CALL_QUEUE_LIST_SUCCESS = 'inquisit-app/INBOUND_CALL_QUEUE/INBOUND_CALL_QUEUE_LIST_SUCCESS';
const INBOUND_CALL_QUEUE_LIST_COUNT_SUCCESS = 'inquisit-app/INBOUND_CALL_QUEUE/INBOUND_CALL_QUEUE_LIST_COUNT_SUCCESS';

const GENERIC = [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL];

const initialState = {
  loadingInboundCallQueue: false,
  preliminaryCount: 0,
  inboundCallQueue: [],
  inboundCallGroups: {},
}

export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case INBOUND_CALL_QUEUE_LIST_REQUEST:
      return {
        ...state,
        loadingInboundCallQueue: true,
      };
    case INBOUND_CALL_QUEUE_LIST_SUCCESS:
      return {
        ...state,
        inboundCallQueue: action.result.success && action.result.inbound_call_queue ? action.result.inbound_call_queue : [],
        inboundCallGroups: action.result.success && action.result.inbound_call_groups ? action.result.inbound_call_groups : {},
        loadingInboundCallQueue: false,
      };
    case INBOUND_CALL_QUEUE_LIST_COUNT_REQUEST:
      return {
        ...state,
        preliminaryCount: 0
      };
    case INBOUND_CALL_QUEUE_LIST_COUNT_SUCCESS:
      return {
        ...state,
        preliminaryCount: action.result && action.result.success ? action.result.inbound_call_queue_stats.call_count : 0,
      }
    default:
      return state;
  }
}

export function getActiveInboundCallQueueItemsCount(params) {
  return {
    types: [INBOUND_CALL_QUEUE_LIST_COUNT_REQUEST, INBOUND_CALL_QUEUE_LIST_COUNT_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/inbound_call_queue/get_active_inbound_call_queue_items_count', {
      params: params
    }),
  };
}

export function getActiveInboundCallQueueItems(params) {
  return {
    types: [INBOUND_CALL_QUEUE_LIST_REQUEST, INBOUND_CALL_QUEUE_LIST_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/inbound_call_queue/get_active_inbound_call_queue_items', {
      params: params
    }),
  };
}

export function getActiveInboundCallQueueItemDetails(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/inbound_call_queue/get_active_inbound_call_queue_item_details', {
      params: params
    }),
  };
}

export function executeCallQueuePrecallLogic(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/inbound_call_queue/execute_call_queue_pre_call_logic', {
      data: data
    }),
  };
}

export function dismissCallFromQueue(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/inbound_call_queue/dismiss_call_from_queue', {
      data: data
    }),
  };
}
