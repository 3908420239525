const LOAD_REQUESTED = 'inquisit-app/recordingUploader/LOAD_REQUESTED';
const GENERIC_SUCCESS = 'inquisit-app/recordingUploader/GENERIC_SUCCESS';
const REQUEST_FAIL = 'inquisit-app/recordingUploader/REQUEST_FAIL';


const initialState = {
};
const GENERIC = [LOAD_REQUESTED, GENERIC_SUCCESS, REQUEST_FAIL];
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}


// SET

export function uploadScreenRecording(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('upload/upload_screen_recording', {
      data: data
    })
  };
}
