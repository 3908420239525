const GENERIC_REQUEST = 'inquisit-app/USER_COMPENSATION/GENERIC_REQUEST';
const GENERIC_FAIL = 'inquisit-app/USER_COMPENSATION/GENERIC_FAIL';
const GENERIC_SUCCESS = 'inquisit-app/USER_COMPENSATION/GENERIC_SUCCESS';

const GENERIC = [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL];

const initialState = {
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

/*
 * GET
 */

export function getCompensationItems(params) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get('/user_compensation/get_user_compensation', {
      params: params
    }),
  };
}

export function getCompensationItemNotes(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/user_compensation/get_compensation_notes', {
      params: params
    }),
  };
}

export function getCompensationDetails(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/user_compensation/get_compensation_details', {
      params: params
    }),
  };
}

export function getCompensationTypes(params) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/user_compensation/get_compensation_types', {
      params: params
    }),
  };
}

/*
 * SET
 */

export function addManualCompensationRequest(params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/user_compensation/add_manual_compensation_request', {
      params: params
    }),
  };
}

export function bulkAddManualCompensationRequest(params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/user_compensation/bulk_add_manual_compensation_request', {
      params: params
    }),
  };
}

export function bulkUpdateCompensationStatus(params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/user_compensation/bulk_update_compensation_status', {
      params: params
    }),
  };
}
 
export function editManualCompensationRequest(params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/user_compensation/edit_compensation_request', {
      params: params
    }),
  };
}

export function deleteCompensationRequest(params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/user_compensation/delete_compensation_request', {
      params: params
    }),
  };
}

export function addCompensationItemNote (params) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/user_compensation/add_compensation_note', {
      params: params
    }),
  };
}
