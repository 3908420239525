const NOTIFICATION_PANEL = 'inquisit-app/alerts/NOTIFICATION_PANEL';
const UPDATE_ALERTS_COUNT = 'inquisit-app/alerts/UPDATE_ALERTS_COUNT';
const UPDATE_ALERTS = 'inquisit-app/alerts/UPDATE_ALERTS';
const LOAD_GENERIC = 'inquisit-app/alerts/LOAD_GENERIC';
const SUCCESS_GENERIC = 'inquisit-app/alerts/SUCCESS_GENERIC';
const SUCCESS_ALERTS = 'inquisit-app/alerts/SUCCESS_ALERTS'; 
const FAIL_GENERIC = 'inquisit-app/alerts/FAIL_GENERIC';
const DISMISS_ALERT = 'inquisit-app/alerts/DISMISS_ALERT';
const RESET_ALERTS = 'inquisit-app/alerts/RESET_ALERTS';

const initialState = {
  isNotificationPanelOpen: false,
  alertsCount: 0,
  alerts: {
    high_priority: [],
    medium_priority: [],
    mentions: [],
    other_role_notifications: [],
    group: [],
  },
  dismissedAlerts: [],
  loading: true
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_GENERIC:
      return {
        ...state,
        loading: true
      };
    case FAIL_GENERIC:
      return {
        ...state,
        loading: false
      };
    case NOTIFICATION_PANEL:
      return {
        ...state,
        isNotificationPanelOpen: action.data
      };
    case UPDATE_ALERTS_COUNT: 
      return {
        ...state,
        alertsCount: {...state.alertsCount, [action.alertType]: action.alertCount},
      };
    case UPDATE_ALERTS: 
      return {
        ...state,
        alerts: action.alerts ? {...state.alerts, [action.alertType]: action.alerts} : initialState.alerts,
        alertsCount: action.alertType === "high_priority" ? state.alerts.mentions.length + action.alerts.length : action.alertType === "mentions" ? state.alerts.high_priority.length + action.alerts.length : state.alertsCount,
      };
    case SUCCESS_ALERTS: 
      return {
        ...state,
        alerts: action.result ? {high_priority: action.result.high_priority, medium_priority: action.result.medium_priority, mentions: action.result.mentions, other_role_notifications: action.result.other_role_notifications, group: action.result.group} : initialState.alerts,
        alertsCount: action.result ? action.result.total_alerts : 0,
        dismissedAlerts: action.result ? action.result.dismissed_alerts : [],
        loading: false
      };
    case RESET_ALERTS:
      return {
        ...state,
        alerts: initialState.alerts,
        alertsCount: 0,
        dismissedAlerts: []
      };
    case DISMISS_ALERT: 
      return {
        ...state,
        dismissedAlerts: [...state.dismissedAlerts, action.alert]
      };
    default:
      return state;
  }
}

// Local set
export function toggleNotificationPanel(value) {
  return {
    type: NOTIFICATION_PANEL,
    data: value
  };
}

export function updateAlertsCount(alertType, alertCount) {
  return {
    type: UPDATE_ALERTS_COUNT,
    alertType: alertType,
    alertCount: alertCount
  };
}

export function updateAlerts(alertType, alerts) {
  return {
    type: UPDATE_ALERTS,
    alertType: alertType,
    alerts: alerts
  };
}

export function dismissAlert(alert) {
  return {
    type: DISMISS_ALERT,
    alert: alert
  };
}

export function resetAlerts() {
  return {
    type: RESET_ALERTS
  };
}

// Get


export function getInAppAlerts() {
  return {
    types: [LOAD_GENERIC, SUCCESS_ALERTS, FAIL_GENERIC],
    promise: (client) => client.get('/in_app_alerts/get_in_app_alerts', {
      params: {}
    })
  };
}

export function getInAppMenteeAlertsByRecipient(to_type_group, to_type_id, menteeId) {
  return {
    types: [LOAD_GENERIC, SUCCESS_GENERIC, FAIL_GENERIC],
    promise: (client) => client.get('/in_app_alerts/get_in_app_mentee_alerts_by_recipient', {
      params: {
        to_type_group: to_type_group,
        to_type_id: to_type_id,
        mentee_id: menteeId,
      }
    })
  };
}

export function getInAppAlertActivity(focusOn, menteeId, mentorId){
  return {
    types: [LOAD_GENERIC, SUCCESS_GENERIC, FAIL_GENERIC],
    promise: (client) => client.get('/in_app_alerts/get_in_app_alert_activity', {
      params: {
        focus_on: focusOn, 
        mentee_id: menteeId, 
        mentor_id: mentorId,
      }
    })
  };
}


//post
export function dismissInAppAlert(alert){
  return {
    types: [LOAD_GENERIC, SUCCESS_GENERIC, FAIL_GENERIC],
    promise: (client) => client.post('in_app_alerts/dismiss_in_app_alerts', {
      data: {
        alert: alert,
      }
    })
  };
}
