const SET_CALL_SID = 'inquisit-app/telephony/SET_CALL_SID';
const CALL_IN_PROGRESS_STATUS = 'inquisit-app/telephony/CALL_IN_PROGRESS_STATUS';
const CALL_MUTE_UNMUTE_STATUS = 'inquisit-app/telephony/CALL_MUTE_UNMUTE_STATUS';
const OUTREACH_MODAL_STATUS = 'inquisit-app/telephony/OUTREACH_MODAL_STATUS';
const CALL_USER_PROPERTIES = 'inquisit-app/telephony/CALL_USER_PROPERTIES';
const INCOMING_CALL_MODAL = 'inquisit-app/telephony/INCOMING_CALL_MODAL';
const INBOUND_CALLER_INFO = 'inquisit-app/telephony/INBOUND_CALLER_INFO';
const INBOUND_CONNECTION = 'inquisit-app/telephony/INBOUND_CONNECTION';
const INBOUND_CALL_IN_PROGRESS = 'inquisit-app/telephony/INBOUND_CALL_IN_PROGRESS';
const CALL_IN_PROGRESS_VIA_PHONE = 'inquisit-app/telephony/CALL_IN_PROGRESS_VIA_PHONE';
const REMOVE_INBOUND_CONNECTION = 'inquisit-app/telephony/REMOVE_INBOUND_CONNECTION';
const RESET_TELEPHONY_DATA = 'inquisit-app/telephony/RESET_TELEPHONY_DATA';
const TELEPHONY_REQUEST_GENERIC = 'inquisit-app/telephony/TELEPHONY_REQUEST_GENERIC';
const TELEPHONY_SUCCESS_GENERIC = 'inquisit-app/telephony/TELEPHONY_SUCCESS_GENERIC';
const TELEPHONY_FAIL_GENERIC = 'inquisit-app/telephony/TELEPHONY_FAIL_GENERIC';

const initialState = {
  call_sid: null,
  call_in_progress: false,
  showOutreachCallCompleteModal: false,
  callUserProperties: {},
  mute: false,
  showIncomingCallModal: false,
  inboundCallerInfo: {id: 0 },
  inboundConnection: false,
  inboundCallInProgress: false,
  callInProgressViaPhone: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CALL_SID:
      return {
        ...state,
        call_sid: action.data
      };
    case CALL_IN_PROGRESS_STATUS:
      return {
        ...state,
        call_in_progress: action.data
      };
    case CALL_MUTE_UNMUTE_STATUS:
      return {
        ...state,
        mute: action.data
      };
    case OUTREACH_MODAL_STATUS:
      return {
        ...state,
        showOutreachCallCompleteModal: action.data
      };
    case CALL_USER_PROPERTIES:
      return {
        ...state,
        callUserProperties: (action.data) ? action.data : {}
      };
    case INCOMING_CALL_MODAL:
      return {
        ...state,
        showIncomingCallModal: action.data
      };
    case INBOUND_CALLER_INFO: {
      let callerInfo = {id: 0};
      if (action.data) {
        callerInfo[action.key] = action.data;
      }
      return {
        ...state,
        inboundCallerInfo: callerInfo
      };
    }
    case INBOUND_CONNECTION:
      return {
        ...state,
        inboundConnection: action.data
      };
    case REMOVE_INBOUND_CONNECTION: {
      let ict = state.inboundConnection;
      delete ict[action.data.key];
      return {
        ...state,
        inboundConnection: (ict) ? ict : {}
      };
    }
    case INBOUND_CALL_IN_PROGRESS:
      return {
        ...state,
        inboundCallInProgress: (action.data) ? action.data : false
      };
    case CALL_IN_PROGRESS_VIA_PHONE:
      return {
        ...state,
        callInProgressViaPhone: (action.data) ? action.data : false
      };
    case RESET_TELEPHONY_DATA:
      return initialState;
    default:
      return state;
  }
}

export function setCallSid(value) {
  return {
    type: SET_CALL_SID,
    data: value
  };
}

export function setCallProgressStatus(value) {
  return {
    type: CALL_IN_PROGRESS_STATUS,
    data: value
  };
}

export function setCallMuteStatus(value) {
  return {
    type: CALL_MUTE_UNMUTE_STATUS,
    data: value
  };
}

export function setIncomingCallModalVisibility(value) {
  return {
    type: INCOMING_CALL_MODAL,
    data: value
  };
}

export function setInboundCallerInfo(key, value) {
  return {
    type: INBOUND_CALLER_INFO,
    key: key,
    data: value
  };
}

export function toggleOutreachCallCompletionModal(value) {
  return {
    type: OUTREACH_MODAL_STATUS,
    data: value
  };
}

export function setCallUserProperties(userProperties) {
  return {
    type: CALL_USER_PROPERTIES,
    data: userProperties
  };
}

export function setInboundConnection(connection) {
  return {
    type: INBOUND_CONNECTION,
    data: connection
  };
}

export function removeInboundConnection(key) {
  return {
    type: REMOVE_INBOUND_CONNECTION,
    data: { key: key}
  };
}

export function setInboundCallInProgress(obj) {
  return {
    type: INBOUND_CALL_IN_PROGRESS,
    data: obj
  };
}

export function setCallInProgressViaPhone(bool) {
  return {
    type: CALL_IN_PROGRESS_VIA_PHONE,
    data: bool
  };
}

export function resetTelephony() {
  return {
    type: RESET_TELEPHONY_DATA
  };
}

export function endIvrCallBySid(callSid) {
  return {
    types: [TELEPHONY_REQUEST_GENERIC, TELEPHONY_SUCCESS_GENERIC, TELEPHONY_FAIL_GENERIC],
    promise: (client) => client.post('/ivrcallback/end_call_by_sid', {
      data: {
        call_sid: callSid
      }
    })
  };
}

export function initiateESCall(menteeId, phoneNumInd, typeGroup, typeId) {
  return {
    types: [TELEPHONY_REQUEST_GENERIC, TELEPHONY_SUCCESS_GENERIC, TELEPHONY_FAIL_GENERIC],
    promise: (client) => client.post('/estelephony/initiate_call', {
      data: {
        mentee_id: menteeId,
        phone_num_ind: phoneNumInd,
        type_group: typeGroup,
        type_id: typeId
      }
    })
  };
}

export function acknowledgeCall(data) {
  return {
    types: [TELEPHONY_REQUEST_GENERIC, TELEPHONY_SUCCESS_GENERIC, TELEPHONY_FAIL_GENERIC],
    promise: (client) => client.post('/estelephony/platform_acknowledge', {
      data: data
    })
  };
}

export function updateESCallStatus({menteeId, status, callTxId, callStatusNote, callRating, callAdditionalNote, callSid, selectedSpokeWith}) {
  return {
    types: [TELEPHONY_REQUEST_GENERIC, TELEPHONY_SUCCESS_GENERIC, TELEPHONY_FAIL_GENERIC],
    promise: (client) => client.post('/es/update_es_tx_post_call_status', {
      data: {
        mentee_id: menteeId,
        status: status,
        call_tx_id: callTxId,
        call_sid: callSid,
        call_rating: callRating,
        call_note: callAdditionalNote,
        call_status_note: callStatusNote,
        spoke_with: selectedSpokeWith,
      }
    })
  };
}

export function updatePNCallStatus({menteeId, callSid, status, callStatusNote, selectedSpokeWith}) {
  return {
    types: [TELEPHONY_REQUEST_GENERIC, TELEPHONY_SUCCESS_GENERIC, TELEPHONY_FAIL_GENERIC],
    promise: (client) => client.post('/peer_navigator/update_pn_tx_post_call_status', {
      data: {
        call_sid: callSid,
        mentee_id: menteeId,
        status: status,
        call_status_note: callStatusNote,
        spoke_with: selectedSpokeWith,
      }
    })
  };
}

export function updateSupporterCallStatus({menteeId, callSid, status, callStatusNote, selectedSpokeWith}) {
  return {
    types: [TELEPHONY_REQUEST_GENERIC, TELEPHONY_SUCCESS_GENERIC, TELEPHONY_FAIL_GENERIC],
    promise: (client) => client.post('/supporter/update_supporter_tx_post_call_status', {
      data: {
        call_sid: callSid,
        mentee_id: menteeId,
        status: status,
        call_status_note: callStatusNote,
        spoke_with: selectedSpokeWith,
      }
    })
  };
}

export function initiateAdminCallViaPhone(data) {
  return {
    types: [TELEPHONY_REQUEST_GENERIC, TELEPHONY_SUCCESS_GENERIC, TELEPHONY_FAIL_GENERIC],
    promise: (client) => client.post('/admintelephony/initiate_call', {
      data: data
    })
  };
}

export function endCall(data) {
  return {
    types: [TELEPHONY_REQUEST_GENERIC, TELEPHONY_SUCCESS_GENERIC, TELEPHONY_FAIL_GENERIC],
    promise: (client) => client.post('/admintelephony/end_call', {
      data: data
    })
  };
}

export function dropVoicemail(data) {
  return {
    types: [TELEPHONY_REQUEST_GENERIC, TELEPHONY_SUCCESS_GENERIC, TELEPHONY_FAIL_GENERIC],
    promise: (client) => client.post('/voicemail/drop_voicemail', {
      data: data
    })
  };
}