const COORDINATOR_REQUESTED = 'inquisit-app/coordinator/COORDINATOR_REQUESTED';
const COORDINATOR_FAIL = 'inquisit-app/coordinator/COORDINATOR_FAIL';
const COORDINATOR_GENERIC_SUCCESS = 'inquisit-app/coordinator/COORDINATOR_GENERIC_SUCCESS';
const MANUAL_TIME_LIST_SUCCESS = 'inquisit-app/coordinator/MANUAL_TIME_LIST_SUCCESS';
const OUTREACH_DASHBOARD_COORDINATOR_COMPENSATION_SUMMARY = 'inquisit-app/coordinator/OUTREACH_DASHBOARD_COORDINATOR_COMPENSATION_SUMMARY';
const OUTREACH_MANUAL_TIME_LISTING_FOR_APPROVAL = 'inquisit-app/outreach_dashboard/OUTREACH_MANUAL_TIME_LISTING_FOR_APPROVAL';

// ADMIN
const COORDINATOR_LIST = 'inquisit-app/coordinator_admin/COORDINATOR_LIST';
const COORDINATOR_DATA = 'inquisit-app/coordinator_admin/COORDINATOR_DATA';

const initialState = {
  coordinatorList: [],
  coordinatorProfile: [],
  manualTimeListing: [],
  coordinatorCompensationSummary: [],
  manualTimeListingForApproval: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case COORDINATOR_REQUESTED:
      return {
        ...state,
      };
    case COORDINATOR_FAIL:
      return {
        ...state,
        error: action.error
      };
    case MANUAL_TIME_LIST_SUCCESS:
      return {
        ...state,
        manualTimeListing: action.result,
      };
    case OUTREACH_DASHBOARD_COORDINATOR_COMPENSATION_SUMMARY: 
      return {
        ...state,
        coordinatorCompensationSummary: (action.result) ? action.result : []
      };
    case OUTREACH_MANUAL_TIME_LISTING_FOR_APPROVAL:
      return {
        ...state,
        manualTimeListingForApproval: (action.result) ? action.result : []
      }
    case COORDINATOR_LIST: 
      return {
        ...state,
        coordinatorList: (action.result) ? action.result : []
      }
    case COORDINATOR_DATA:
      return {
        ...state,
        coordinatorProfile: (action.result) ? action.result : []
      }
    default:
      return state;
  }
}

// Get
export function getCoordinatorCompensationSummary(data) {
  return {
    types: [COORDINATOR_REQUESTED, OUTREACH_DASHBOARD_COORDINATOR_COMPENSATION_SUMMARY, COORDINATOR_FAIL],
    promise: (client) => client.get('/getcoordinatordata/get_outreach_payment_summary_by_coordinator', {
      params: data
    })
  };
}

export function getCoordinatorManualTimeList(data) {
  return {
    types: [COORDINATOR_REQUESTED, MANUAL_TIME_LIST_SUCCESS, COORDINATOR_FAIL],
    promise: (client) => client.get('/getcoordinatordata/get_coordinator_manual_time_list', {
      params: data
    })
  };
}

export function getManualTimeListingForApproval(data) {
  return {
    types: [COORDINATOR_REQUESTED, OUTREACH_MANUAL_TIME_LISTING_FOR_APPROVAL, COORDINATOR_FAIL],
    promise: (client) => client.get('/getcoordinatordata/get_manual_time_listing_for_approval', {
      params: data
    })
  };
}

export function getCoordinatorList(data) {
  return {
    types: [COORDINATOR_REQUESTED, COORDINATOR_LIST, COORDINATOR_FAIL],
    promise: (client) => client.get('/admin/getcoordinatordata/get_coordinator_list', {
      params: data
    })
  };
}

export function getCoordinatorData(data) {
  return {
    types: [COORDINATOR_REQUESTED, COORDINATOR_DATA, COORDINATOR_FAIL],
    promise: (client) => client.get('/admin/getcoordinatordata/get_coordinator_data', {
      params: data
    })
  };
}

export function getCoordinatorCheckinData() {
  return {
    types: [COORDINATOR_REQUESTED, COORDINATOR_GENERIC_SUCCESS, COORDINATOR_FAIL],
    promise: (client) => client.get('/admin/getcoordinatordata/get_coordinator_checkin_status', {
      params: {}
    })
  };
}


// Set
export function addManualTime(object) {
  return {
    types: [COORDINATOR_REQUESTED, COORDINATOR_GENERIC_SUCCESS, COORDINATOR_FAIL],
    promise: (client) => client.post('/setcoordinatordata/add_manual_time', {
      data: object
    })
  };
}

export function approveManualTime(object) {
  return {
    types: [COORDINATOR_REQUESTED, COORDINATOR_GENERIC_SUCCESS, COORDINATOR_FAIL],
    promise: (client) => client.post('/setcoordinatordata/approve_manual_time', {
      data: object
    })
  };
}

export function updateCoordinatorData(object) {
  return {
    types: [COORDINATOR_REQUESTED, COORDINATOR_GENERIC_SUCCESS, COORDINATOR_FAIL],
    promise: (client) => client.post('/setcoordinatordata/update_coordinator_data', {
      data: object
    })
  };
}

export function updateCoordinatorManualTime(object) {
  return {
    types: [COORDINATOR_REQUESTED, COORDINATOR_GENERIC_SUCCESS, COORDINATOR_FAIL],
    promise: (client) => client.post('/setcoordinatordata/update_coordinator_manual_time', {
      data: object
    })
  };
}

export function setCoordinatorCheckinStatus(object) {
  return {
    types: [COORDINATOR_REQUESTED, COORDINATOR_GENERIC_SUCCESS, COORDINATOR_FAIL],
    promise: (client) => client.post('/setcoordinatordata/set_coordinator_checkin_status', {
      data: object
    })
  };
}

export function checkAndUpdateCoordinatorCheckinStatus() {
  return {
    types: [COORDINATOR_REQUESTED, COORDINATOR_GENERIC_SUCCESS, COORDINATOR_FAIL],
    promise: (client) => client.post('/setcoordinatordata/check_and_set_coordinator_checkin_status', {
      data: {}
    })
  };
}
