import firebase from 'Firebase.js';
import 'firebase/compat/database';

import { fbRefreshToken, updateUser } from 'redux/modules/auth';
import store from '../store';
export const FirebasePresenceHelper = {
  create(user) {
    if(!user) return false;
    if (firebase && firebase.auth() && firebase.auth().currentUser) {
      this._createUser(user);
    } else {
      this.authenticateFb(user, () => {
        this._createUser(user);
      });
    }
  },
  markAvailable(user, forceRefresh = 0) {
    if(!user) return false;
    if (firebase.auth().currentUser) {
      // console.log(firebase.auth().currentUser);
      let presenceRef = firebase.database().ref('presence/' + user.role + user.id);
      presenceRef.once('value').then(snapshot => {
        let presence = snapshot.val();
        let is_avail = presence && presence.was_available ? presence.was_available : (user.group===25 ? 0 : 1);
        let calling_enabled = user.hasOwnProperty('calling_enabled_ind') ? (user.calling_enabled_ind===1 ? is_avail : 0) : is_avail;
        presenceRef.update({is_available: calling_enabled, force_refresh: forceRefresh});
        firebase.database().ref('presence/' + user.role + user.id+'/was_available').remove();
      })
      
    } else {
      this.authenticateFb(user, () => {
        let presenceRef = firebase.database().ref('presence/' + user.role + user.id);
        presenceRef.once('value').then(snapshot => {
          let presence = snapshot.val();
          let is_avail = presence && presence.was_available ? presence.was_available : 0;
          let calling_enabled = user.hasOwnProperty('calling_enabled_ind') ? (user.calling_enabled_ind===1 ? is_avail : 0) : is_avail;
          presenceRef.update({is_available: calling_enabled, force_refresh: forceRefresh});
          firebase.database().ref('presence/' + user.role + user.id+'/was_available').remove();
        })
      });
    }
  },
  markUnavailable(user) {
    if(!user) return false;
    let presenceRef = firebase.database().ref('presence/' + user.role + user.id);
    presenceRef.once('value').then(snapshot => {
      let presence = snapshot.val();
      presenceRef.update({is_available: 0, was_available: presence ? (presence.was_available ?  presence.was_available : (presence.is_available ? presence.is_available : 0)) : 0});
    })
    
  },
  markAvailability(user, ind, forceRefresh = 0) {
    if(!user) return false;
    let presenceRef = firebase.database().ref('presence/' + user.role + user.id);
    presenceRef.update({is_available: ind, force_refresh: forceRefresh});
  },
  remove(user) {
    if(!user) return false;
    let presenceRef = firebase.database().ref('presence/' + user.role + user.id);
    presenceRef.remove();
  },
  updateAvailability(oldUser, newUser){
    if(!oldUser || !newUser) return false;
    let oldPresenceRef = firebase.database().ref('presence/' + oldUser.role + oldUser.id);
    oldPresenceRef.once('value').then(snapshot => {
      let presence = snapshot.val();
      oldPresenceRef.remove();
      let newPresenceRef = firebase.database().ref('presence/' + newUser.role + newUser.id);
      newPresenceRef.update({is_available: presence && presence.is_available ? presence.is_available : 0, force_refresh: 0});
    })
  },
  _createUser(user) {
    let presenceRef = firebase.database().ref('presence/' + user.role + user.id);
    presenceRef.onDisconnect().remove();
    presenceRef.set({id: user.id});
    presenceRef.update({group: user.group});
    presenceRef.update({order: user.group !== 26 ? user.group: user.group-2+(user.inbound_call_priority_order ? user.inbound_call_priority_order : 99) /100});
    if(user.associated_orgs_str) presenceRef.update({org_string: user.associated_orgs_str});
    if(user.group === 25) presenceRef.update({enable_inbound_mentee_call_ind: user.enable_inbound_mentee_call_ind ? 1 : 0});
    if(user.languages) presenceRef.update({languages: user.languages});
    // presenceRef.update({is_available: user.calling_enabled_ind ? user.calling_enabled_ind : 0});
    // if(user.telephony_token){
      // TwilioHelper.initTelephony(user.telephony_token);
    // }
  },
  authenticateFb(user, callback) {
    if(!user) return false;
    firebase.auth().signInWithCustomToken(user.fb_token).catch((error) =>  {
      // Handle Errors here.
      let errorCode = error.code;
      if (errorCode === 'auth/invalid-custom-token') {
        store.dispatch(fbRefreshToken()).then((res) => {
          if (res.result) {
            firebase.auth().signInWithCustomToken(res.result).then(() =>  {
              store.dispatch(updateUser({'fb_token': res.result}));
              if (callback) callback();
            });
          }
        });
      }
    });
  }
};

