const MENTEE_LOAD_REQUESTED = 'inquisit-app/mentee/MENTEE_LOAD_REQUESTED';
const MENTEE_GENERIC_REQUEST = 'inquisit-app/mentee/MENTEE_GENERIC_REQUEST';
const MENTEE_ATTRIB_SUCCESS = 'inquisit-app/mentee/MENTEE_ATTRIB_SUCCESS';
const MENTEE_FAIL = 'inquisit-app/mentee/MENTEE_FAIL';
const MENTEE_PROFILE_REQUEST = 'inquisit-app/mentee/MENTEE_PROFILE_REQUEST';
const MENTEE_PROFILE_SUCCESS = 'inquisit-app/mentee/MENTEE_PROFILE_SUCCESS';
const MENTEE_AVAILABILITY_SUCCESS =
  'inquisit-app/mentee/MENTEE_AVAILABILITY_SUCCESS';
const MENTEE_CLINICAL_DEMO_DATA_SUCCESS =
  'inquisit-app/mentee/MENTEE_CLINICAL_DEMO_DATA_SUCCESS';
const MENTEE_CLINICAL_DEMO_FIELD_SUCCESS =
  'inquisit-app/mentee/MENTEE_CLINICAL_DEMO_FIELD_SUCCESS';
const MENTEE_SET_AVAILABILITY_SUCCESS =
  'inquisit-app/mentee/MENTEE_SET_AVAILABILITY_SUCCESS';
const MENTEE_LIST_SUCCESS = 'inquisit-app/mentee/MENTEE_LIST_SUCCESS';

// const MENTEE_STATUS_DATA_SUCCESS = 'inquisit-app/mentee/MENTEE_STATUS_DATA_SUCCESS';
const MENTEE_ENROLLMENT_PROGRESS_SUCCESS =
  'inquisit-app/mentee/MENTEE_ENROLLMENT_PROGRESS_SUCCESS';
const MENTEE_GENERIC_SUCCESS = 'inquisit-app/mentee/MENTEE_GENERIC_SUCCESS';
const MENTEE_ADD_WAITLIST_SUCCESS =
  'inquisit-app/mentee/MENTEE_ADD_WAITLIST_SUCCESS';
const UPDATE_MENTEE_SUCCESS = 'inquisit-app/mentee/UPDATE_MENTEE_SUCCESS';
const MENTEE_CHART_DATA_SUCCESS =
  'inquisit-app/mentee/MENTEE_CHART_DATA_SUCCESS';
const MENTEE_CLINICAL_MEASURE_CHART_DATA_SUCCESS =
  'inquisit-app/mentee/MENTEE_CLINICAL_MEASURE_CHART_DATA_SUCCESS';
const MENTEE_CALL_TX_DATA_REQUEST =
  'inquisit-app/mentee/MENTEE_CALL_TX_DATA_REQUEST';
const MENTEE_CALL_TX_DATA_SUCCESS =
  'inquisit-app/mentee/MENTEE_CALL_TX_DATA_SUCCESS';
const MENTEE_CALL_TX_DATA_FAIL =
  'inquisit-app/mentee/MENTEE_CALL_TX_DATA_FAIL';
const MENTEE_STATUS_SUCCESS = 'inquisit-app/mentee/MENTEE_STATUS_SUCCESS';
const MENTEE_STATUS_AND_BASE_LIST_SUCCESS =
  'inquisit-app/mentee/MENTEE_STATUS_AND_BASE_LIST_SUCCESS';
const MENTEE_PHASE_LIST_SUCCESS =
  'inquisit-app/mentee/MENTEE_PHASE_LIST_SUCCESS';
const MENTEE_PHASE_OPTIONS_SUCCESS =
  'inquisit-app/mentee/MENTEE_PHASE_OPTIONS_SUCCESS';
const MENTEE_UNASSIGNED_CHALLENGES_SUCCESS =
  'inquisit-app/mentee/MENTEE_UNASSIGNED_CHALLENGES_SUCCESS';
const MENTEE_WL_MH_RW_ER_SUCCESS =
  'inquisit-app/mentee/MENTEE_WL_MH_RW_ER_SUCCESS';
const MENTEE_WL_METADATA_SUCCESS =
  'inquisit-app/mentee/MENTEE_WL_METADATA_SUCCESS';
const MENTEE_DC_AND_MS_METADATA_SUCCESS =
  'inquisit-app/mentee/MENTEE_DC_AND_MS_METADATA_SUCCESS';
const MENTEE_SMS_DATA_SUCCESS = 'inquisit-app/mentee/MENTEE_SMS_DATA_SUCCESS';
const MENTEE_PPQ_RESPONSE_SUCCESS =
  'inquisit-app/mentee/MENTEE_PPQ_RESPONSE_SUCCESS';
const MENTEE_ISSUE_REQUEST = 'inquisit-app/mentee/MENTEE_ISSUE_REQUEST';
const MENTEE_ISSUE_SUCCESS = 'inquisit-app/mentee/MENTEE_ISSUE_SUCCESS';

const MENTEE_CLINICAL_MEASURES_REQUEST = 'inquisit-app/mentee/MENTEE_CLINICAL_MEASURES_REQUEST';
const MENTEE_CLINICAL_MEASURES_SUCCESS = 'inquisit-app/mentee/MENTEE_CLINICAL_MEASURES_SUCCESS';
const MENTEE_CLINICAL_MEASURES_CHART_REQUEST = 'inquisit-app/mentee/MENTEE_CLINICAL_MEASURES_CHART_REQUEST';
const MENTEE_CLINICAL_MEASURES_CHART_SUCCESS = 'inquisit-app/mentee/MENTEE_CLINICAL_MEASURES_CHART_SUCCESS';
const MENTEE_QUALITY_METRICS_SUCCESS = 'inquisit-app/mentee/MENTEE_QUALITY_METRICS_SUCCESS';


const initialState = {
  loading: false,
  profileLoading: false,
  id: 1,
  menteeAttribs: {
    clinical: [],
    core: {},
    demographic: [],
    top_line_summary: '',
  },
  menteeProfile: {},
  statuses: [],
  menteeAndBaseStatusList: [],
  phaseList: [],
  availability: [],
  clinicalDemoData: {},
  clinicalDemoFields: [],
  statusOptions: [],
  enrollmentProgress: {},
  list: {
    currentPage: 1,
    total: 0,
    results: 0,
    mentees: [],
    lastPage: 1,
  },
  esList: [],
  callTxLoading: false,
  callTx: {
    data: [],
    total: 0,
  },
  outreachCallTx: {
    data: [],
    total: 0,
  },
  pmCallEventList: {
    data: [],
    total: 0,
  },
  pnCallEventList: {
    data: [],
    total: 0,
  },
  partnerCallEventList: {
    data: [],
    total: 0,
  },
  programCallTx: {
    data: [],
    total: 0,
  },
  charts: {},
  clinicalChartData: {},
  callStatus: {
    today: {
      scheduled: {
        count: 57,
      },
      missed: {
        count: 3,
        percent: -3,
        periodDays: 30,
      },
      successful: {
        count: 25,
        percent: 50,
      },
      voicemailLeft: {
        count: 20,
        percent: 40,
      },
      someoneElseAnswered: {
        count: 5,
        percent: 10,
      },
      averageTime: '35m: 32s',
    },
    tomorrow: {
      scheduled: {
        count: 11,
      },
      missed: {
        count: 3,
        percent: -3,
        periodDays: 30,
      },
      successful: {
        count: 25,
        percent: 50,
      },
      voicemailLeft: {
        count: 20,
        percent: 40,
      },
      someoneElseAnswered: {
        count: 5,
        percent: 10,
      },
      averageTime: '35m: 32s',
    },
  },
  sopStatus: [],
  phaseOptions: [],
  unassignedChallenges: [],
  challenges_properties: [],
  waitlistList: {
    match_history_list: [],
    reason_waitlisted_list: [],
    extra_resources_list: [],
  },
  disenrollCodesList: [],
  disenrollMenteeStatusList: [],
  issues: {
    loading: false,
    data: [],
  },
  clinicalMeasure: {
    loading: false,
    title: '',
    data: null,
    orgGoal: 0,
    labels: [],
    datasets: []
  },
  qualityMetrics: [],
  mentorList: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MENTEE_LOAD_REQUESTED:
      state.list.mentees = [];
      return {
        ...state,
        loading: true,
      };
    case MENTEE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.result,
      };
    case MENTEE_ATTRIB_SUCCESS:
      return {
        ...state,
        menteeAttribs: action.result
          ? action.result
          : initialState.menteeAttribs,
      };
    case MENTEE_PROFILE_REQUEST:
      return {
        ...state,
        profileLoading: true,
      };
    case MENTEE_PROFILE_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        menteeProfile: action.result.mentee_profile
          ? action.result.mentee_profile
          : {},
      };
    case MENTEE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: action.result ? action.result : [],
      };
    case MENTEE_CLINICAL_DEMO_DATA_SUCCESS:
      return {
        ...state,
        clinicalDemoData: action.result ? action.result : {},
      };
    case MENTEE_CLINICAL_DEMO_FIELD_SUCCESS:
      return {
        ...state,
        clinicalDemoFields: action.result ? action.result : [],
      };
    case MENTEE_CHART_DATA_SUCCESS:
      return {
        ...state,
        charts: action.result,
      };
    case MENTEE_PHASE_OPTIONS_SUCCESS:
      return {
        ...state,
        phaseOptions: action.result ? action.result : [],
      };
    case MENTEE_UNASSIGNED_CHALLENGES_SUCCESS:
      return {
        ...state,
        unassignedChallenges: action.result.challenges
          ? action.result.challenges
          : [],
        challenges_properties: action.result.properties
          ? action.result.properties
          : [],
      };
    case MENTEE_CALL_TX_DATA_REQUEST:
      return {
        ...state,
        callTxLoading: true,
      };
    case MENTEE_CALL_TX_DATA_SUCCESS:
      return {
        ...state,
        callTxLoading: false,
        callTx: action.result.admin_call_tx,
        outreachCallTx: action.result.outreach_call_tx,
        programCallTx: action.result.program_call_tx
          ? {
            data: action.result.program_call_tx,
            total: action.result.program_call_tx.length,
          }
          : { data: [], total: 0 },
        pmCallEventList: action.result.pm_call_event_list ? {
            data: action.result.pm_call_event_list,
            total: action.result.pm_call_event_list.length,
          }
          : { data: [], total: 0 },
        pnCallEventList: action.result.pn_call_event_list ? {
            data: action.result.pn_call_event_list,
            total: action.result.pn_call_event_list.length,
          }
          : { data: [], total: 0 },
        supporterCallEventList: action.result.supporter_call_event_list ? {
            data: action.result.supporter_call_event_list,
            total: action.result.supporter_call_event_list.length,
          }
          : { data: [], total: 0 },
        partnerCallEventList: action.result.partner_call_event_list ? {
            data: action.result.partner_call_event_list,
            total: action.result.partner_call_event_list.length,
          }
          : { data: [], total: 0 },
      };
    case MENTEE_CALL_TX_DATA_FAIL:
      return {
        ...state,
        callTxLoading: false,
      };
    case MENTEE_ENROLLMENT_PROGRESS_SUCCESS:
      return {
        ...state,
        enrollmentProgress: action.result ? action.result : {},
      };
    case MENTEE_CLINICAL_MEASURE_CHART_DATA_SUCCESS:
      return {
        ...state,
        clinicalChartData: action.result,
      };
    case MENTEE_STATUS_SUCCESS:
      return {
        ...state,
        statuses: action.result,
      };
    case MENTEE_STATUS_AND_BASE_LIST_SUCCESS:
      return {
        ...state,
        menteeAndBaseStatusList: action.result,
      };
    case MENTEE_PHASE_LIST_SUCCESS:
      return {
        ...state,
        phaseList: action.result,
      };
    case MENTEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MENTEE_WL_MH_RW_ER_SUCCESS:
      return {
        ...state,
        waitlistList: action.result,
      };
    case MENTEE_DC_AND_MS_METADATA_SUCCESS:
      return {
        ...state,
        disenrollMenteeStatusList: action.result.mentee_status_list,
      };
    case MENTEE_ISSUE_REQUEST:
      return {
        ...state,
        issues: { loading: true, data: [] },
      };
    case MENTEE_ISSUE_SUCCESS:
      return {
        ...state,
        issues: {
          loading: false,
          data: action.result.issues ? action.result.issues : [],
        },
      };
    case MENTEE_CLINICAL_MEASURES_REQUEST:
      return {
        ...state,
        clinicalMeasure: { loading: true, data: null },
      };
    case MENTEE_CLINICAL_MEASURES_SUCCESS:
      return {
        ...state,
        clinicalMeasure: {
          loading: false,
          title: action.result.chart_properties ? action.result.chart_properties.options.label : '',
          data: action.result.chart_properties ? action.result.chart_properties : null,
          orgGoal: action.result.org_goal ? action.result.org_goal : 0,
          labels: action.result.labels ? action.result.labels : null,
          datasets: action.result.datasets ? action.result.datasets : null
        }
      }
    case MENTEE_QUALITY_METRICS_SUCCESS:
      return {
        ...state,
        qualityMetrics: action.result.quality_metrics ? action.result.quality_metrics : []
      }
    default:
      return state;
  }
}

export function getMenteeList(params) {
  return {
    types: [MENTEE_LOAD_REQUESTED, MENTEE_LIST_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/mentee_list', {
        params: params,
      }),
  };
}

export function getMenteeWaitlist(data) {
  return {
    types: [MENTEE_LOAD_REQUESTED, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/mentee_waitlist', {
        params: {
          admin_id: data.admin_id,
          root_id: data.root_id,
          org_id: data.org_id,
        },
      }),
  };
}

export function getMenteeAttribs(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_ATTRIB_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/mentee_attribs_for_mentor_panel', {
        params: {
          mentee_id: data.mentee_id,
        },
      }),
  };
}

export function getMenteeProfile(data) {
  return {
    types: [MENTEE_PROFILE_REQUEST, MENTEE_PROFILE_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/mentee_profile', {
        params: {
          mentee_id: data.mentee_id,
        },
      }),
  };
}

export function getAvailability(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_AVAILABILITY_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/availability', {
        params: {
          mentee_id: data.mentee_id,
        },
      }),
  };
}

export function getClinicalDemographicData(data) {
  return {
    types: [
      MENTEE_GENERIC_REQUEST,
      MENTEE_CLINICAL_DEMO_DATA_SUCCESS,
      MENTEE_FAIL,
    ],
    promise: client =>
      client.get(
        '/admin/getmenteesdata/mentee_profile_clinical_demographic_formatted_data',
        {
          params: {
            mentee_id: data.mentee_id,
          },
        },
      ),
  };
}

export function getMenteeChartData(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_CHART_DATA_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/get_mentee_questions_chart_data', {
        params: data,
      }),
  };
}

export function getMenteeClinicalMeasureChartData(data) {
  return {
    types: [
      MENTEE_GENERIC_REQUEST,
      MENTEE_CLINICAL_MEASURE_CHART_DATA_SUCCESS,
      MENTEE_FAIL,
    ],
    promise: client =>
      client.get(
        '/admin/getmenteesdata/get_mentee_clinical_measure_chart_data',
        {
          params: data,
        },
      ),
  };
}

// export function getMenteeStatusOptions(data) {
//   return {
//     types: [MENTEE_LOAD_REQUESTED, MENTEE_STATUS_DATA_SUCCESS, MENTEE_FAIL],
//     promise: (client) => client.get('/admin/getmenteesdata/mentee_status_options', {
//       params: data
//     })
//   };
// }

export function getMenteeCallTx(data) {
  return {
    types: [MENTEE_CALL_TX_DATA_REQUEST, MENTEE_CALL_TX_DATA_SUCCESS, MENTEE_CALL_TX_DATA_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/mentee_call_tx', {
        params: data,
      }),
  };
}

export function getMenteeStatus() {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_STATUS_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/mentee_status', {
        params: {},
      }),
  };
}

export function getMenteeAndBaseStatusList() {
  return {
    types: [
      MENTEE_GENERIC_REQUEST,
      MENTEE_STATUS_AND_BASE_LIST_SUCCESS,
      MENTEE_FAIL,
    ],
    promise: client =>
      client.get('/admin/getmenteesdata/mentee_and_mpb_status_list', {
        params: {},
      }),
  };
}

export function getMenteeAndEPBaseStatusList() {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/mentee_and_ep_status_list', {
        params: {},
      }),
  };
}

export function getPhaseList(rootId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_PHASE_LIST_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/phase_list', {
        params: {
          root_id: rootId,
        },
      }),
  };
}

export function getMenteeAttributeHeaders(
  menteeId,
  adminInd,
  importantOnlyInd,
) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteesdata/mentee_attribute_headers', {
        params: {
          mentee_id: menteeId,
          admin_ind: adminInd,
          important_only_ind: importantOnlyInd,
        },
      }),
  };
}

export function getMenteeAttributesFlattened(
  menteeId,
  adminInd,
  importantOnlyInd,
) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteesdata/mentee_attribs_flattened', {
        params: {
          mentee_id: menteeId,
          admin_ind: adminInd,
          important_only_ind: importantOnlyInd,
        },
      }),
  };
}

export function getMenteeClinicalMeasureChartByClinicalMeasureId(data) {
  return {
    types: [
      MENTEE_CLINICAL_MEASURES_REQUEST,
      MENTEE_CLINICAL_MEASURES_SUCCESS,
      MENTEE_FAIL,
    ],
    promise: client =>
      client.get('/getmenteesdata/mentee_clinical_measure_chart', {
        params: {
          mentee_id: data.menteeId,
          clinical_measure_id: data.clinicalMeasureId,
        },
      }),
  };
}

export function getMenteeClinicalMeasureCharts(data) {
  return {
    types: [
      MENTEE_CLINICAL_MEASURES_CHART_REQUEST,
      MENTEE_CLINICAL_MEASURES_CHART_SUCCESS,
      MENTEE_FAIL,
    ],
    promise: client =>
      client.get('/getmenteesdata/mentee_clinical_measure_charts', {
        params: data,
      }),
  };
}

export function getMenteeQuestionsChartData(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmentoringprogramdata/get_checkin_questions_chart_data', {
        params: {
          mentoring_program_base_id: data.mentoringProgramBaseId,
          show_top_how_many: data.showTopHowMany,
        },
      }),
  };
}

export function getMenteeChallengeQuestionsChartData(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get(
        '/getmentoringprogramdata/get_challenge_questions_chart_data',
        {
          params: {
            mpb_pathway_id: data.mpbPathwayId,
          },
        },
      ),
  };
}

export function getMenteeBasicInfo(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteesdata/mentee_basic_info', {
        params: {
          mentee_id: data.menteeId,
          mentor_id: data.mentorId,
          verify_ind: data.verify,
        },
      }),
  };
}

export function getMenteeScheduleAvailability(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteesdata/mentee_schedule_availability', {
        params: {
          mentor_id: data.mentorId,
          mentee_id: data.menteeId,
          availability_start_date: data.availabilityStartDate,
          preferred_ind: data.preferredInd,
        },
      }),
  };
}

export function getMenteeAvailabilityDaysOfWeek(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteesdata/mentee_availability_days_of_week', {
        params: {
          mentee_id: menteeId,
        },
      }),
  };
}

export function getClinicalDemographicFields(data) {
  return {
    types: [
      MENTEE_GENERIC_REQUEST,
      MENTEE_CLINICAL_DEMO_FIELD_SUCCESS,
      MENTEE_FAIL,
    ],
    promise: client =>
      client.get('/getenrolldata/get_mentee_demo_clinical_fields', {
        params: {
          org_id: data.org_id,
        },
      }),
  };
}

export function checkFitbitAuthorization(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteesdata/check_fitbit_authorization', {
        params: {
          mentee_id: menteeId
        },
      }),
  };
}


export function getMenteePhaseOptions(mpbId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_PHASE_OPTIONS_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmentoringprogramdata/get_mentee_phase_options', {
        params: {
          mentoring_program_base_id: mpbId,
        },
      }),
  };
}

export function getUnassignedChallenges(mpbId, rootId) {
  return {
    types: [
      MENTEE_GENERIC_REQUEST,
      MENTEE_UNASSIGNED_CHALLENGES_SUCCESS,
      MENTEE_FAIL,
    ],
    promise: client =>
      client.get('/getmentoringprogramdata/get_unassigned_challenges', {
        params: {
          root_id: rootId,
          mentoring_program_base_id: mpbId,
        },
      }),
  };
}

export function getMatchHistoryReasonWaitlistedExtraResourcesList(rootId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_WL_MH_RW_ER_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get(
        '/admin/getmenteesdata/get_match_history_reason_waitlisted_extra_resources_list',
        {
          params: {
            root_id: rootId,
          },
        },
      ),
  };
}

export function getReasonWaitlistedList(rootId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get(
        '/admin/getmenteesdata/get_reason_waitlisted_list',
        {
          params: {
            root_id: rootId,
          },
        },
      ),
  };
}


export function getWaitlistMetadataByMentee(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_WL_METADATA_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/get_waitlist_metadata_by_mentee', {
        params: {
          mentee_id: menteeId,
        },
      }),
  };
}

export function getDisenrollCodesAndApplicableMenteeStatuses() {
  return {
    types: [
      MENTEE_GENERIC_REQUEST,
      MENTEE_DC_AND_MS_METADATA_SUCCESS,
      MENTEE_FAIL,
    ],
    promise: client =>
      client.get(
        '/admin/getmenteesdata/get_disenroll_codes_and_applicable_mentee_statuses',
        {},
      ),
  };
}

export function getMenteeSmsData(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_SMS_DATA_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/get_mentee_sms_data', {
        params: {
          mentee_id: menteeId,
        },
      }),
  };
}

export function getMenteePpqResponse(menteeId, outreachTargetId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_PPQ_RESPONSE_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/get_mentee_ppq_response', {
        params: {
          mentee_id: menteeId,
          outreach_target_id: outreachTargetId,
        },
      }),
  };
}

export function getMenteePeopleToMentions(menteeId, other={}) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/mentions_for_notes', {
        params: {
          mentee_id: menteeId,
          ...other
        },
      }),
  };
}

export function getIssuesForNotes(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/get_issues_for_notes', {
        params: {
          mentee_id: menteeId,
        },
      }),
  };
}

export function getMenteeQuickSummary(menteeMasterId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/get_profile_quick_summary', {
        params: {
          mentee_master_id: menteeMasterId,
        },
      }),
  };
}


export function getPartnerMentions(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/mentions_for_notes', {
        params: {
          mentee_id: menteeId,
        },
      }),
  };
}

export function getMenteeForYourMentorResponses(menteeMasterId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteesdata/mentee_for_your_mentor_responses', {
        params: {
          mentee_master_id: menteeMasterId,
        },
      }),
  };
}

export function getQualityMetricsData(menteeMasterId, rootId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_QUALITY_METRICS_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.get('/admin/getmenteesdata/get_quality_metrics_data', {
      params: {
        mentee_master_id: menteeMasterId,
        root_id: rootId
      }
    })
  };
}

export function checkQualityMetricsDataExists(menteeMasterId, qmId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.get('/admin/getmenteesdata/check_quality_metrics_data_exists', {
      params: {
        mentee_master_id: menteeMasterId,
        qm_id: qmId
      }
    })
  };
}

export function getRecentActivity(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteeactivity/get_recent_activity', {
        params: {
          mentee_id: data.menteeId,
        },
      }),
  };
}

export function getFilteredActivity(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteeactivity/get_recent_activity_by_dates', {
        params: {
          mentee_id: data.menteeId,
          start_date: data.start_date,
          end_date: data.end_date
        },
      }),
  };
}

export function getRecentMenteeLog(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteeactivity/get_recent_mentee_logs', {
        params: {
          mentee_id: data.menteeId,
        },
      }),
  };
}

export function getMoreRecentActivityData(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteeactivity/pull_more_recent_activity_data', {
        params: {
          mentee_id: data.menteeId,
          data_length: data.data_length,
          total_counts: data.total_counts,
          intv_index: data.intv_index,
          more: data.more,
          finished: data.finished
        },
      }),
  };
}

export function getMoreRecentMenteeLog(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/getmenteeactivity/pull_more_recent_mentee_logs', {
        params: {
          mentee_id: data.menteeId,
          offset: data.offset
        },
      }),
  };
}

export function getMenteeTags(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.get('/admin/getmenteesdata/get_mentee_tags', {
      params: {
        mentee_id: menteeId
      }
    })
  };
}

export function getMenteeDetailList(params) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.get('/mentees/get_mentee_detail_list', {
      params: params
    })
  };
}




// Post
export function updateAvailability(object) {
  return {
    types: [
      MENTEE_GENERIC_REQUEST,
      MENTEE_SET_AVAILABILITY_SUCCESS,
      MENTEE_FAIL,
    ],
    promise: client =>
      client.post('/admin/setmenteedata/save_availability_data', {
        data: object,
      }),
  };
}

export function updateAttributes(object) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/admin/setmenteedata/update_mentee_attributes', {
        data: object,
      }),
  };
}

export function updateMenteeCallTx(object) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/admin/setmenteedata/update_mentee_call_tx', {
        data: {
          call_sid: object.callSid,
          note: object.note,
        },
      }),
  };
}

export function bulkAddMenteeToWaitlist(object) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_ADD_WAITLIST_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/setmenteedata/bulk_add_mentee_waitlist', {
        data: object,
      }),
  };
}

export function updateMentee(object) {
  return {
    types: [MENTEE_GENERIC_REQUEST, UPDATE_MENTEE_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/setmenteedata/update_mentee', {
        data: {
          mentee_id: object.menteeId,
          update_arr: object.menteeUpdateArr,
        },
      }),
  };
}

export function addClinicalMeasure(menteeId, clinicalMeasureObj) {
  return {
    types: [MENTEE_GENERIC_REQUEST, UPDATE_MENTEE_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/setmenteedata/add_clinical_measure', {
        data: {
          mentee_id: menteeId,
          clinical_measure: clinicalMeasureObj,
          clinical_measure_id: 1,
        },
      }),
  };
}

export function addQualityMeasure(qualityMeasureObj) {
  return {
    types: [MENTEE_GENERIC_REQUEST, UPDATE_MENTEE_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.post('/setmenteedata/add_quality_measure', {
      data: {
        // mentee_master_id: menteeMasterId,
        quality_measure: qualityMeasureObj,
        // quality_measure_id: qualityMeasureId
      }
    })
  };
}

export function UnauthorizeFitbitDevice(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/es/setmenteedata/unauthorize_mentee_fitbit_device', {
        params: {
          mentee_id: menteeId
        },
      }),
  };
}

// REMOVE THIS
export function rescheduleCallBlock(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/setmentoringprogramdata/reschedule_call_block', {
        data: {
          mentoring_program_base_id: data.baseId,
          current_phase: data.currentPhase,
          date_and_time: data.dateAndTime,
          mode: data.mode,
          note: data.note,
          note_template: data.noteTemplate,
          within_preferred_date_window_ind: data.withinPreferredDateWindowInd,
          explain_why_not_preferred_date: data.explainWhyNotPreferredDate,
        },
      }),
  };
}

export function getMenteeVoicemails(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.get('/admin/getmenteesdata/get_all_mentee_voicemails', {
        params: {
          mentee_id: menteeId,
        },
      }),
  };
}

export function updateMenteeVoicemailStatus(menteeId, status) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/setmenteedata/update_mentee_voicemail_status', {
        data: {
          id: menteeId,
          status: status,
        },
      }),
  };
}

export function setMenteeComment(
  menteeId,
  fbKey,
  comment,
  typeGroup,
  typeGroupId,
  stepId,
  orgId,
) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/admin/setmenteedata/process_comment', {
        data: {
          mentee_id: menteeId,
          fb_key: fbKey,
          comment: JSON.stringify(comment),
          type_group: typeGroup,
          type_group_id: typeGroupId,
          step_id: stepId,
          org_id: orgId,
        },
      }),
  };
}

export function updateMenteeComment(fbKey, comment) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/admin/setmenteedata/update_comment', {
        data: {
          fb_key: fbKey,
          comment: JSON.stringify(comment),
        },
      }),
  };
}

export function inviteMenteeAsMentor(menteeId) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: client =>
      client.post('/admin/setmenteedata/invite_mentee_as_mentor', {
        data: {
          mentee_id: menteeId,
        },
      }),
  };
}

export function updateMenteeConditionAssociation(object) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.post('admin/setmenteedata/associate_mentee_condition', {
      data: object
    })
  };
}

export function insertMenteeTag(menteeId, tag) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.post('admin/setmenteedata/insert_mentee_tag', {
      data: {
        mentee_id: menteeId,
        tag: tag
      }
    })
  };
}

export function deleteMenteeTag(id) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.post('admin/setmenteedata/delete_mentee_tag', {
      data: {
        id: id
      }
    })
  };
}

export function updateMenteeOptionByProp(mentee_id, prop_type, update_prop) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.post('mentee/update_mentee_options_prop', {
      data: {
        mentee_id,
        prop_type,
        update_prop
      }
    })
  };
}

export function addQmQualityMeasure(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.post('/setmenteequalitymeasuredata/add_edit_quality_measure', {
      data: data
    })
  };
}

export function deleteQmQualityMeasure(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.post('/setmenteequalitymeasuredata/delete_quality_measure', {
      data: data
    })
  };
}

export function changeComplianceInd(data){
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.post('/setmenteequalitymeasuredata/change_compliance_ind', {
      data: data
    })
  };
}

export function unarchiveMentee(data) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.post('/es/unarchive_mentee', {
      data: data
    })
  };
}


export function getMenteeNumCallsWithinAvailability(mentee_id) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.get('admin/getmenteesdata/mentee_num_calls_within_availability', {
      params: {
        mentee_id
      }
    })
  };
}

export function getMenteeFirstPeerAttemptDate(mentee_id, program) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.get('admin/getmenteesdata/first_peer_attempt_date', {
      params: {
        mentee_id: mentee_id,
        program: program
      }
    })
  };
}

export function getMenteeRXData(mentee_id) {
  return {
    types: [MENTEE_GENERIC_REQUEST, MENTEE_GENERIC_SUCCESS, MENTEE_FAIL],
    promise: (client) => client.get('es/get_es_mentee_rx_data', {
      params: {
        mentee_id: mentee_id
      }
    })
  };
}