import { logout as revokeToken } from "redux/modules/auth";
import { FirebasePresenceHelper } from 'helpers/FirebasePresenceHelper';
// import { TwilioHelper2 } from 'helpers/TwilioHelper2';
import { PusherHelper } from "./PusherHelper";
import { MatomoHelper } from "./MatomoHelper";
import store from 'store';


export const logoutPreserveRoute = () => {
    const user = store.getState().auth.user
    sessionStorage.setItem('redirectToUrl', window.location.hash.replace('#',''));
    sessionStorage.setItem('redirectToQuery', window.location.search);
    if (user) {
      sessionStorage.setItem('sessionUser', JSON.stringify({group: user?.group, id: user?.id}));
      _logout();
    } else {
        window.location.href = "/";
    }
}

export const logout = () => {
    sessionStorage.removeItem('sessionUser');
    sessionStorage.removeItem('redirectToUrl');
    sessionStorage.removeItem('redirectToQuery');

    _logout();
}

const _logout = () => {
    const user = store.getState().auth.user
    const userManager = store.getState().userManager;
    FirebasePresenceHelper.remove(user);
    MatomoHelper.trackLogInOutEvent(user, 'out');
    PusherHelper.disconnect();
    store.dispatch(revokeToken()).then(() => {
        userManager.userManager.removeUser();
    });
}