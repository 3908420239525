import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import Routes from './routes';
import './styles/fonts.css';
import 'assets/kit-mentor1to1-daf44cdc/css/embedded-woff.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import './styles/main.css';
// import './styles/cms.css';
// import 'font-awesome/css/font-awesome.min.css';
// import './styles/react-bootstrap-table-all.min.css';

import './styles/demo.css';
import './new-theme/main.css';
import './new-theme/tailwind_output.css';
import 'react-tooltip/dist/react-tooltip.css'

import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      light: '#66c4ff',
      dark: '#0067c0',
      main: '#3392FF',
      contrastText: '#fff'
    },
    blue: {
      main: '#006DEB',
      contrastText: '#fff',
      light: '#79c2ff',
      dark: '#0065cb'
    },
    secondary: {
      main: '#e25139'
    },
    success: {
      main: '#5cb85c'
    },
    warning: {
      main: '#f0ad4e'
    },
    danger: {
      main: '#d9534f'
    },
    error: {
      light: '#ff8577',
      dark: '#aa1c23',
      main: '#e2534b'
    },
    orange: {
      main: '#ff8100'
    },
    info: {
      main: '#17a2b8'
    }
  },
  typography: {
    fontFamily: 'Calibre'
  }
});

const config = {
  custom: {
    families: ['Calibre', 'Calibre-Bold', 'Calibre-Semibold', 'Calibre-Light', 'Calibre-Medium']
  }
};

const container = document.getElementById('content');
const root = createRoot(container);

root.render(
  <WebfontLoader config={config}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store} key="provider">
        <Routes />
      </Provider>
    </ThemeProvider>
  </WebfontLoader>
);

