const GENERIC_REQUEST = 'inquisit-app/communication/GENERIC_REQUEST';
const GENERIC_SUCCESS = 'inquisit-app/communication/GENERIC_SUCCESS';
const GENERIC_FAIL = 'inquisit-app/communication/GENERIC_FAIL';

const initialState = {
  communicationSentInd: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GENERIC_REQUEST:
      return {
        ...state,
        communicationSentInd: false
      }
    case GENERIC_SUCCESS:
      return {
        ...state,
        communicationSentInd: true
      };
    case GENERIC_FAIL:
      return {
        ...state,
        communicationSentInd: true
      };
    default:
      return state;
  }
}

// App-related communication
export function appSendLink(type, typeId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/admin/communication/app_send_link', {
      data: {
        type: type,
        type_id: typeId
      }
    })
  };
}

export function appResetPwAndSendAppCredentials(type, typeId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/admin/communication/app_reset_pw_and_send_app_credentials', {
      data: {
        type: type,
        type_id: typeId
      }
    })
  };
}

export function sendLoginCode(type, typeId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post('/admin/communication/app_send_login_code', {
      data: {
        type: type,
        type_id: typeId
      }
    })
  };
}

