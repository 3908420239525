import { FirebaseTrackerHelper } from 'helpers/FirebaseTrackerHelper';
import { logoutPreserveRoute } from 'helpers/LogoutHelper';

let oldHash = '';
export default () => next => action => {
	if (oldHash !== window.location.hash) {
	  	if (FirebaseTrackerHelper.trackRoute) FirebaseTrackerHelper.trackRoute();	
	}
	oldHash = window.location.hash;
  	if (action.error && (action.error.status === 401 || action.error.error === 'Not Authorized')) {
	  	if (window.location.hash !== '#/' && window.location.hash !== '#/login' && window.location.hash !== '#/login_callback' && window.location.hash !== '/login_callback') {
	  		logoutPreserveRoute();
		} else {
			return false;
		}
  	}
  	return next(action);
};
