// @flow
const GENERIC_LOAD = 'GET_ES_GENERIC_TODO_REQUEST';
const GENERIC_SUCCESS = 'GET_ES_GENERIC_TODO_SUCCESS';
const GENERIC_FAIL = 'GET_ES_GENERIC_TODO_FAIL';

const TODO_LOAD = 'GET_ES_TODO_BLOCKER_REQUEST';
const TODO_SUCCESS = 'GET_ES_TODO_BLOCKER_SUCCESS';
const TODO_FAIL = 'GET_ES_TODO_BLOCKER_FAIL';

const GENERIC = [GENERIC_LOAD, GENERIC_SUCCESS,GENERIC_FAIL];
const initialState = {
  blockerTasks: false,
  blockerPmuCourses: false
};

export default function reducer(state = initialState, action = {}) {
 
  switch (action.type) {
    case TODO_SUCCESS:
      return {
        ...state,
        blockerTasks: action.result && action.result.success ? action.result.tasks : false,
        blockerPmuCourses: action.result && action.result.success ? action.result.pmu_courses : false
      }
    default:
      return state;
  }
}

export function addMentorTodoItem(mentorId,description,allowManualAction=1,dueDate=null,menteeId = null,metadata=null){
  return {
    types: GENERIC,
    promise: client =>
      client.post('mentor/add_mentor_todo_task',{
        data:{
          mentor_id:mentorId,
          description:description,
          due_date:JSON.stringify(dueDate), 
          allow_manual_action_ind:allowManualAction ? 1 : 0,
          metadata:JSON.stringify(metadata),
          mentee_id:menteeId,
        }
      }),
  };
}

export function bulkAddMentorTodoItem(data){
  return {
    types: GENERIC,
    promise: client =>
      client.post('mentor/bulk_add_mentor_todo_task',{
        data: data
      }),
  };
}

export function updateMentorTodoItem(id,mentorId,description,allowManualAction=1,dueDate=null,menteeId = null ,metadata=null){
  let obj = {
          id:id,
          mentor_id:mentorId,
          description:description,
          due_date:JSON.stringify(dueDate),
          allow_manual_action_ind:allowManualAction ? 1 : 0,
          metadata:JSON.stringify(metadata),
          mentee_id:menteeId,
        };
  return {
    types: GENERIC,
    promise: client =>
      client.post('mentor/update_mentor_todo_task',{
        data:obj,
      }),
  };
}

export function getMentorTodoSurveyList(mentorId){
  return {
    types: GENERIC,
    promise: client =>
      client.get('mentor/get_mentor_todo_survey_list',  {
        params:{
          mentor_id:mentorId,
        }
      }),
  };
}

export function getAdminMentorTodoList(mentorId){
  return {
    types: GENERIC,
    promise: client =>
      client.get('mentor/get_admin_mentor_todo_list',  {
        params:{
          mentor_id:mentorId,
        }
      }),
  };
}

export function getMentorTodoList(data){
  return {
    types: GENERIC,
    promise: client => client.get('mentor/get_mentor_todo_list',  {
        params: data,
      }),
  };
}

export function getMentorDashboardTasks(params){
  return {
    types: GENERIC,
    promise: client => client.get('mentor/get_mentor_dashboard_todo_list',  {
        params: params,
      }),
  };
}

export function getMentorBlockerTasks(mentorId) {
  return {
    types: [TODO_LOAD, TODO_SUCCESS, TODO_FAIL],
    promise: client => client.get('mentor/get_mentor_blocker_tasks',  {
        params: {
          mentor_id: mentorId
        },
      }),
  };
}

export function checkIfAdminCallScheduled(data) {
    return {
    types: GENERIC,
    promise: client => client.get('mentor/check_if_admin_call_scheduled',  {
        params: data,
      }),
  };
}

export function markTodoComplete(id,appsyncId=null,isChecked=1){
  return {
    types: GENERIC,
    promise: client =>
      client.post('mentor/set_mentor_todo_complete',  {
        data:{
          id:id,
          is_checked:isChecked,
          appsync_id:appsyncId,
        }
      }),
  };
}
export function markAdminNeedingReviewComplete(id,appsyncId=null){
  return {
    types: GENERIC,
    promise: client =>
      client.post('mentor/set_mentor_todo_admin_review_complete',  {
        data:{
          id:id,
          appsync_id:appsyncId,
        }
      }),
  };
}
export function deleteTodoTask(id){
  return {
    types: GENERIC,
    promise: client =>
      client.post('mentor/delete_mentor_todo_task',  {
        data:{
          id:id,
        }
      }),
  };
}

export function processTaskSurveyResponse(data){
  return {
    types: GENERIC,
    promise: client =>
      client.post('mentor/process_task_survey_response',  {
        data:data,
    }),
  };
}

export function processTaskResponse(data){
  return {
    types: GENERIC,
    promise: client =>
      client.post('mentor/process_task_response',  {
        data:data,
    }),
  };
}

export function addMentorCompetencyDomainTask(data){
    return {
    types: GENERIC,
    promise: (client) => client.post('/mentor/add_mentor_competency_domain_task', {
      data: data
    })
  };
}


