// const GENERIC_REQUEST = 'inquisit-app/PUSHER/GENERIC_REQUEST';
// const GENERIC_SUCCESS = 'inquisit-app/PUSHER/GENERIC_SUCCESS';
// const GENERIC_FAIL = 'inquisit-app/PUSHER/GENERIC_FAIL';

const AUTH_REQUEST = 'inquisit-app/PUSHER/AUTH_REQUEST';
const AUTH_SUCCESS = 'inquisit-app/PUSHER/AUTH_SUCCESS';
const AUTH_FAIL = 'inquisit-app/PUSHER/AUTH_FAIL';

const INITIALIZE_PUSHER = 'inquisit-app/PUSHER/INITIALIZE_PUSHER';
const INITIALIZE_MENTEE_PUSHER = 'inquisit-app/PUSHER/INITIALIZE_MENTEE_PUSHER';

const initialState = {
  userChannel: null,
  groupChannel: null,
  menteeChannel: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INITIALIZE_PUSHER:
      return {
        ...state,
        userChannel: action.result.userChannel,
        groupChannel: action.result.groupChannel,
      };
    case INITIALIZE_MENTEE_PUSHER:
      return {
        ...state,
        menteeChannel: action.result.menteeChannel,
      };
    default:
      return state;
  }
}


export function initializePusher(userChannel, groupChannel){
  return {
    type: INITIALIZE_PUSHER,
    result: {userChannel: userChannel, groupChannel: groupChannel},
  }
}

export function initializeMenteePusherChannel(menteeChannel){
  return {
    type: INITIALIZE_MENTEE_PUSHER,
    result: {menteeChannel: menteeChannel},
  }
}

export function pusherAuth(socketId, channelName) {
  return {
    types: [AUTH_REQUEST, AUTH_SUCCESS, AUTH_FAIL],
    promise: (client) => client.post('/pusher_auth/pusher_channel_auth', {
      params: {
        socket_id: socketId,
        channel_name: channelName,
      }
    })
  };
}
