const LOAD_GENERIC = 'inquisit-app/PORTAL/LOAD_GENERIC'; 
const SUCCESS_GENERIC = 'inquisit-app/PORTAL/SUCCESS_GENERIC'; 
const FAIL_GENERIC = 'inquisit-app/PORTAL/FAIL_GENERIC'; 

const SET_PORTAL_LIST_FILTER = 'inquisit-app/PORTAL/SET_PORTAL_LIST_FILTER'; 
const SET_ISSUE_DETAIL_TAB = 'inquisit-app/PORTAL/SET_ISSUE_DETAIL_TAB'; 

const LIST_LOAD = 'inquisit-app/PORTAL/LIST_LOAD'; 
const LIST_LOAD2 = 'inquisit-app/PORTAL/LIST_LOAD2'; 
const LIST_SUCCESS = 'inquisit-app/PORTAL/LIST_SUCCESS'; 
const LIST_FAIL = 'inquisit-app/PORTAL/LIST_FAIL'; 
const LIST_DISMISSED_SUCCESS = 'inquisit-app/PORTAL/LIST_DISMISSED_SUCCESS'; 
const LIST_DISMISSED_FAIL = 'inquisit-app/PORTAL/LIST_DISMISSED_FAIL'; 

const PARTNER_STATUS_LIST_SUCCESS = 'inquisit-app/PORTAL/CLIENT_STATUS_LIST';

const GENERIC = [LOAD_GENERIC, SUCCESS_GENERIC, FAIL_GENERIC];

const initialState = {
  listLoading: false,
  partnerStatusList: [],
  listFilters: {
    tag: null
  },
  data: [],
  assignedToList: false,
  issuesList: [],
  substatusesList: [],
  menteeStatusList: [],
  issueDetailTab: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_LOAD: 
      return {
        ...state,
        listLoading: true
      };
    case LIST_LOAD2: 
      return {
        ...state,
        listLoading: false
      };
    case LIST_SUCCESS:
      return {
        ...state,
        data: action.result && action.result.data ? action.result.data : [],
        issuesList: action.result && action.result.issues_list ? action.result.issues_list : [],
        substatusesList: action.result && action.result.substatuses_list ? action.result.substatuses_list : [],
        assignedToList: action.result && action.result.assigned_to_list ? action.result.assigned_to_list : {},
        menteeStatusList: action.result && action.result.mentee_status_list ? action.result.mentee_status_list : [],
        programBaseStatusList: action.result && action.result.program_base_status_list ? action.result.program_base_status_list : [],
        listLoading: false
      };
    case LIST_DISMISSED_SUCCESS: {
      let newData = state.data.slice(0, -1);
      return {
        ...state,
        data: [...newData, ...(action.result && action.result.data ? action.result.data : [])],
        listLoading: false
      };
    }
    case LIST_DISMISSED_FAIL:
      return {
        ...state,
        listLoading: false
      };
    case LIST_FAIL:
      return {
        ...state,
        listLoading: false
      };
    case SET_PORTAL_LIST_FILTER:
      return {
        ...state,
        listFilters: {
          ...state.listFilters,
          [action.data.key]: action.data.value
        }
      }
    case SET_ISSUE_DETAIL_TAB:
      return {
        ...state,
        issueDetailTab: action.data.tab,
      }
    case PARTNER_STATUS_LIST_SUCCESS:
     return {
        ...state,
        partnerStatusList: action.result
      }
    default:
      return state;
  }
}

// Get
export function getAllPeerNavigatorIssues(showLoadingInd){
  return {
    types: [showLoadingInd ? LIST_LOAD : LIST_LOAD2, LIST_SUCCESS, LIST_FAIL],
    promise: (client) => client.get('get/portal/all_peer_navigator_issues', {
      params: {}
    })
  };
}

export function getAllPartnerIssues(showLoadingInd = true, selected_partner) {
  return {
    types: [showLoadingInd ? LIST_LOAD : LIST_LOAD2, LIST_SUCCESS, LIST_FAIL],
    promise: (client) => client.get('get/portal/all_partner_issues', {
      params: {
        selected_partner
      }
    })
  };
}

export function getAllMentorIssues(showLoadingInd = true) {
  return {
    types: [showLoadingInd ? LIST_LOAD : LIST_LOAD2, LIST_SUCCESS, LIST_FAIL],
    promise: (client) => client.get('get/portal/all_mentor_issues', {
      params: {}
    })
  };
}

export function getAllAdminIssues(selectedOrg, selectedRoot, showLoadingInd = true, inboxType = 'issues_inbox') {
  return {
    types: [showLoadingInd ? LIST_LOAD : LIST_LOAD2, LIST_SUCCESS, LIST_FAIL],
    promise: (client) => client.get('get/portal/all_admin_issues', {
      params: {
        org_id: selectedOrg,
        root_id: selectedRoot,
        inbox_type: inboxType
      }
    })
  };
}

export function getLogisticalDismissedIssues(selectedOrg, selectedRoot, showLoadingInd = true, inboxType = 'logistical_issues') {
  return {
    types: [showLoadingInd ? LIST_LOAD : LIST_LOAD2, LIST_DISMISSED_SUCCESS, LIST_DISMISSED_FAIL],
    promise: (client) => client.get('get/portal/get_logistical_dismissed_issues', {
      params: {
        org_id: selectedOrg,
        root_id: selectedRoot,
        inbox_type: inboxType
      }
    })
  };
}

export function getMenteeIssues(typeGroup, menteeId) {
  return {
    types: GENERIC,
    promise: (client) => client.get('get/portal/mentee_issues', {
      params: {type_group: typeGroup, mentee_id: menteeId}
    })
  };
}

export function getStepAssignToList(trackStepId, orgId, menteeId) {
  return {
    types: GENERIC,
    promise: (client) => client.get('get/portal/step_assign_to_list', {
      params: {track_step_id: trackStepId, org_id: orgId, mentee_id: menteeId}
    })
  };
}

export function getStepAssignToListPeerNavigatorMatchProspects(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('get/portal/step_assign_to_list_peer_navigator_match_prospects', {
      params: data
    })
  };
}

export function getTagsToAssignList(orgId) {
  return {
    types: GENERIC,
    promise: (client) => client.get('get/portal/tags_to_assign_list', {
      params: {
        org_id: orgId
      }
    })
  };
}

export function getEventLog(typeGroup, typeId, priority, timestamp) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/portal/event_log', {
      params: {
        type_group: typeGroup,
        type_id: typeId,
        priority: priority,
        timestamp: timestamp
      }
    })
  };
}

export function getTrackEventLog(esTrackStepId, priority) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/portal/track_event_log', {
      params: {
        es_track_step_id: esTrackStepId,
        priority: priority,
      }
    })
  };
}

export function getPartnerStatusList() {
  return {
    types: [LOAD_GENERIC, PARTNER_STATUS_LIST_SUCCESS, FAIL_GENERIC],
    promise: (client) => client.get('/get/portal/get_partner_status_list')
  };
}

export function getPartnerStepDetails(trackStepId, menteeId = 0) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/portal/partner_step_details', {
      params: {
        track_step_id: trackStepId,
        mentee_id: menteeId
      }
    })
  };
}

export function getPartnerVoicemails(menteeId) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/portal/get_partner_voicemails', {
      params: {
        mentee_id: menteeId
      }
    })
  };
}

export function getMentorStepDetails(trackStepId, menteeId = 0) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/portal/mentor_step_details', {
      params: {
        track_step_id: trackStepId,
        mentee_id: menteeId
      }
    })
  };
}

export function getAdminStepDetails(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/portal/admin_step_details', {
      params: data
    })
  };
}

export function getJITFeedbackQuestions(trackStepId, action, actionDetails) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/portal/jit_feedback_questions', {
      params: {
        track_step_id: trackStepId,
        action: action,
        action_details: actionDetails
      }
    })
  };
}

export function getAllPartnersList() {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/portal/all_partners_list', {
      params: {}
    })
  };
}

export function getReferralProspects(data) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/portal/referral_prospects', {
      params: data
    })
  };
}

// Set
export function assignStepInternalWorkflow(track_step_id, assigned_to_type_group, assigned_to_type_id, track_step_substatus_id, note = '', pushback = false, type_group_type_id = null, discuss_with_mentee = 0, urgent_ind = 0) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/assign_step_internal_workflow', {
      data: {
        track_step_id,
        assigned_to_type_group,
        assigned_to_type_id,
        track_step_substatus_id,
        note,
        pushback,
        type_group_type_id,
        discuss_with_mentee,
        urgent_ind
      }
    })
  }
}

export function assignStepClientWorkflow(assigned_to_type_id, assigned_to_type_group, track_step_id, esp_track_step_assigned_to_party_id, es_program_track_step_status_id, is_push_back, note = '', is_urgent_ind = 0) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/assign_step_client_workflow', {
      data: {
        assigned_to_type_id,
        assigned_to_type_group,
        track_step_id,
        esp_track_step_assigned_to_party_id,
        es_program_track_step_status_id,
        is_push_back,
        note,
        is_urgent_ind,
      }
    })
  }
}

export function updateIssueForAutoAssignment(es_program_track_step_assigned_to_party_id, queued_for_auto_assignment_ind = 1) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/update_issue_for_auto_assignment', {
      data: {
        es_program_track_step_assigned_to_party_id,
        queued_for_auto_assignment_ind
      }
    })
  }
}

export function addAssignTag(es_program_track_step_id, tag, org_id) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/add_and_assign_tag', {
      data: {
        es_program_track_step_id,
        tag,
        org_id
      }
    })
  }
}

export function assignExistingTag(org_x_tag_id, es_program_track_step_id) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/assign_existing_tag', {
      data: {
        org_x_tag_id,
        es_program_track_step_id,
      }
    })
  }
}

export function removeAssignedTag(track_step_tag_id) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/remove_assigned_tag', {
      data: {
        track_step_tag_id
      }
    })
  }
}

export function updateStepStatusSubstatus(trackStepStatusId, trackStatus, activeStepInd, activePartnerSubstatus, trackStepId, caseNote, trackStepAssignedToPartyId, feedback, logisitcalSupportInd, esProgramIssueId) {
  
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/update_step_status_substatus', {
      data: {
        es_program_track_step_status_id: trackStepStatusId,
        status: trackStatus,
        active_step_ind: activeStepInd,
        substatus: activePartnerSubstatus,
        track_step_id: trackStepId,
        case_note: caseNote,
        esp_track_step_assigned_to_party_id: trackStepAssignedToPartyId,
        feedback: feedback,
        logistical_support_ind: logisitcalSupportInd,
        es_program_issue_id: esProgramIssueId
      }
    })
  }
}

export function updateStepIssueDetails(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/update_step_issue_details', {
      data: {
        track_step_id: data.trackStepId,
        issue_details: data.issueDetails,
        emergency_ind: data.emergencyInd
      }
    })
  }
}

export function storeJITFeedback(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/store_jit_feedback', {
      data: {
        track_step_id: data.trackStepId,
        feedback: data.feedback
      }
    })
  }
}


// Local sets
export function setFilter(filterKey, filterValue) {
  return {
    type: SET_PORTAL_LIST_FILTER,
    data: {
      key: filterKey,
      value: filterValue
    }
  };
}

export function setIssueDetailTab(tab) {
  return {
    type: SET_ISSUE_DETAIL_TAB,
    data: {
      tab: tab
    }
  };
}

export function changeIssueUrgentInd(data) {
  return {
    types: GENERIC,
    promise: (client) => client.post('/set/portal/change_issue_urgent_ind', { data: data })
  }
}