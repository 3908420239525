const OUTREACH_LOAD_REQUESTED = 'inquisit-app/outreach/OUTREACH_LOAD_REQUESTED';
const OUTREACH_LIST_LOAD_REQUESTED = 'inquisit-app/outreach/OUTREACH_LIST_LOAD_REQUESTED';
const OUTREACH_PRIORITIZED_LIST_LOAD_REQUESTED = 'inquisit-app/outreach/OUTREACH_PRIORITIZED_LIST_LOAD_REQUESTED';
const OUTREACH_FAIL = 'inquisit-app/outreach/OUTREACH_FAIL';
const OUTREACH_PRIORITIZED_FAIL = 'inquisit-app/outreach/OUTREACH_PRIORITIZED_FAIL';
const OUTREACH_FILTERS_SET = 'inquisit-app/outreach/OUTREACH_FILTERS_SET';
const OUTREACH_LIST_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_LIST_LOAD_SUCCESS';
const OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_SUCCESS';
const OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_REQUESTED = 'inquisit-app/outreach/OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_REQUESTED';
const OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_FAILED = 'inquisit-app/outreach/OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_FAILED';
const OUTREACH_PRIORITIZED_MENTEES_LIST_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_PRIORITIZED_MENTEES_LIST_LOAD_SUCCESS';
const OUTREACH_MENTORS_LIST_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_MENTORS_LIST_LOAD_SUCCESS';
const OUTREACH_ARCHIVED_MENTORS_LIST_LOAD_SUCCESS = 'inquisit-app/outreach/OUTREACH_ARCHIVED_MENTORS_LIST_LOAD_SUCCESS';
const OUTREACH_TARGET_DETAILS_SUCCESS = 'inquisit-app/outreach/OUTREACH_TARGET_DETAILS_SUCCESS';
const OUTREACH_PHONES_NUMBERS_SUCCESS = 'inquisit-app/outreach/OUTREACH_PHONES_NUMBERS_SUCCESSOUTREACH_PHONES_NUMBERS_SUCCESS';
const OUTREACH_TARGET_RECENT_ATTEMPTS_SUCCESS = 'inquisit-app/outreach/OUTREACH_TARGET_RECENT_ATTEMPTS_SUCCESS';
const OUTREACH_FOLLOW_UP_REMINDER_SUCCESS = 'inquisit-app/outreach/OUTREACH_FOLLOW_UP_REMINDER_SUCCESS';
const OUTREACH_SCRIPT_SUCCESS = 'inquisit-app/outreach/OUTREACH_SCRIPT_SUCCESS';
const OUTREACH_SCHEDULE_FOLLOWUP_CALL_REMINDER_SUCCESS = 'inquisit-app/outreach/OUTREACH_SCHEDULE_FOLLOWUP_CALL_REMINDER_SUCCESS';
const OUTREACH_CANCEL_FOLLOWUP_CALL_REMINDER_SUCCESS = 'inquisit-app/outreach/OUTREACH_CANCEL_FOLLOWUP_CALL_REMINDER_SUCCESS';
const OUTREACH_REMOVE_FROM_LIST_SUCCESS = 'inquisit-app/outreach/OUTREACH_REMOVE_FROM_LIST_SUCCESS';
const OUTREACH_REST_UPDATE_FLAGS_SUCCESS = 'inquisit-app/outreach/OUTREACH_REST_UPDATE_FLAGS_SUCCESS';
const OUTREACH_REQUEST_GENERIC = 'inquisit-app/outreach/OUTREACH_REQUEST_GENERIC';
const OUTREACH_SUCCESS_GENERIC = 'inquisit-app/outreach/OUTREACH_SUCCESS_GENERIC';
const OUTREACH_FAIL_GENERIC = 'inquisit-app/outreach/OUTREACH_FAIL_GENERIC';
const OUTREACH_TARGET_ARCHIVE_STATUS_LIST_SUCCESS = 'inquisit-app/outreach/OUTREACH_TARGET_ARCHIVE_STATUS_LIST_SUCCESS';
const OUTREACH_TARGET_ARCHIVE_SUBSTATUS_LIST_SUCCESS = 'inquisit-app/outreach/OUTREACH_TARGET_ARCHIVE_SUBSTATUS_LIST_SUCCESS';
const OUTREACH_IVR_STATUS_LIST_SUCCESS = 'inquisit-app/outreach/OUTREACH_IVR_STATUS_LIST_SUCCESS';
const OUTREACH_TARGET_ACTIVE_STATUS_LIST_SUCCESS = 'inquisit-app/outreach/OUTREACH_TARGET_ACTIVE_STATUS_LIST_SUCCESS';
const OUTREACH_UPDATE_OUTREACH_TARGET_ACTIVE_SUCCESS = 'inquisit-app/outreach/OUTREACH_UPDATE_OUTREACH_TARGET_ACTIVE_SUCCESS';
const OUTREACH_TARGET_CALL_TX_STATUS_LIST_SUCCESS = 'inquisit-app/outreach/OUTREACH_TARGET_CALL_TX_STATUS_LIST_SUCCESS';
const OUTREACH_TARGET_CALL_TX_SUBSTATUS_LIST_SUCCESS = 'inquisit-app/outreach/OUTREACH_TARGET_CALL_TX_SUBSTATUS_LIST_SUCCESS';
const OUTREACH_ROOT_LEVEL_FILTERS_SUCCESS = 'inquisit-app/outreach/OUTREACH_ROOT_LEVEL_FILTERS_SUCCESS';
const OUTREACH_TARGET_COMMUNICATION_DATA_SUCCESS = 'inquisit-app/outreach/OUTREACH_TARGET_COMMUNICATION_DATA_SUCCESS';
const OUTREACH_CALL_QUEUE_LIST = 'inquisit-app/outreach/OUTREACH_CALL_QUEUE_LIST';
const OUTREACH_HISTORY_MODAL = 'inquisit-app/outreach/OUTREACH_HISTORY_MODAL';
const OUTREACH_RESET_MENTORS_SUCCESS = 'inquisit-app/outreach/OUTREACH_RESET_MENTORS_SUCCESS';
const OUTREACH_RESET_MENTEES_SUCCESS = 'inquisit-app/outreach/OUTREACH_RESET_MENTEES_SUCCESS';
const OUTREACH_LANGUAGE_LIST_SUCCESS = 'inquisit-app/outreach/OUTREACH_LANGUAGE_LIST_SUCCESS';

const UPDATE_ACTIVE_PAGE_NUMBER_AND_PAGE_SIZE = 'inquisit-app/outreach/UPDATE_ACTIVE_PAGE_NUMBER_AND_PAGE_SIZE';
const UPDATE_ACTIVE_PAGE_TAB = 'inquisit-app/outreach/UPDATE_ACTIVE_PAGE_TAB';

const initialState = {
  loading: false,
  archivedLoading: false,
  loadingPrioritized: false,
  baseHeaders: null,
  archivedBaseHeaders: null,
  mentees: [],
  prioritizedMentees: [],
  tabsManager: {
    tab: 1,
    page: {
      '1' : 1
    },
  },
  archivedMentees: [],
  mentors: [],
  archivedMentors:[],
  pageData: {},
  targetDetail: {
    additional_details: [],
    core_details: {},
  },
  recentAttempts: [],
  scheduleFollowup: null,
  selectedDate: null,
  selectedTime: null,
  talkingPoints: null,
  followUpCallScheduled: false,
  followUpCallCancelled: false,
  outreachRemoved: false,
  outreachTargetArchiveStatusList: [],
  outreachTargetArchiveSubstatusList: [],
  outreachIVRStatusList: [],
  outreachActiveStatusList: [],
  outreachActiveFilterStatusList: [],
  outreachTargetCallTxStatusList: [],
  outreachTargetCallTxStatusListRaw: [],
  outreachTargetCallTxSubstatusList: [],
  outreachRootLevelFilters: [],
  outreachTargetStatus: null,
  filters: {},
  outreachCallQueue: [],
  outreachHistoryModal: false,
  languageList: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OUTREACH_LOAD_REQUESTED:
      return {
        ...state,
      };
    case OUTREACH_LIST_LOAD_REQUESTED:
      return {
        ...state,
        loading: true,
        mentees: [],
      };
      //Below case has been moved
    case OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_REQUESTED:
      return {
        ...state,
        archivedLoading: true,
        archivedMentees: [],
      };
    case OUTREACH_PRIORITIZED_LIST_LOAD_REQUESTED:
      return {
        ...state,
        loadingPrioritized: true,
        prioritizedMentees: [],
      };
    case OUTREACH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OUTREACH_PRIORITIZED_FAIL:
      return {
        ...state,
        loadingPrioritized: false,
        error: action.error,
        prioritizedMentees: []
      };
    case OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_FAILED: 
      return {
        ...state,
        archivedLoading: false, 
        error: action.error,
        archivedMentees: []
      };
    case OUTREACH_FILTERS_SET:
      return {
        ...state,
        filters: action.result ? action.result : {},
      };
    case OUTREACH_LIST_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        mentees: action.result.outreach_target_list ? action.result.outreach_target_list : [],
        baseHeaders: action.result.base_headers,
      };
    case OUTREACH_PRIORITIZED_MENTEES_LIST_LOAD_SUCCESS: {
      return {
        ...state,
        loadingPrioritized: false,
        prioritizedMentees: action.result.outreach_target_list ? action.result.outreach_target_list : [],
        baseHeaders: action.result.base_headers,
      };
    }
    case OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_SUCCESS:
      return {
        ...state,
        archivedLoading: false,
        archivedMentees: action.result.outreach_target_list ? action.result.outreach_target_list : [],
        archivedBaseHeaders: action.result.base_headers,
      };
    case OUTREACH_MENTORS_LIST_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        mentors: action.result.outreach_target_list ? action.result.outreach_target_list : [],
        pageData: {
          currentPage: action.result.currentPage ? action.result.currentPage : 0,
          lastPage: action.result.lastPage ? action.result.lastPage : 1,
          results: action.result.results ? action.result.results : 0,
          total: action.result.total ? action.result.total : 1,
        },
        baseHeaders: action.result.base_headers,
      }; 
      case OUTREACH_ARCHIVED_MENTORS_LIST_LOAD_SUCCESS:
        
      return {
        ...state,
        loading: false,
        archivedMentors: action.result.outreach_target_list ? action.result.outreach_target_list : [],
        pageData: {
          currentPage: action.result.currentPage ? action.result.currentPage : 0,
          lastPage: action.result.lastPage ? action.result.lastPage : 1,
          results: action.result.results ? action.result.results : 0,
          total: action.result.total ? action.result.total : 1,
        },
        baseHeaders: action.result.base_headers,
      };
    case OUTREACH_RESET_MENTORS_SUCCESS:
      return {
        ...state,
        mentors: [],
        archivedMentors: [],
      };
    case OUTREACH_RESET_MENTEES_SUCCESS:
      return {
        ...state,
        mentees: [],
        archivedMentees: [],
        prioritizedMentees: [],
      };
    case OUTREACH_TARGET_DETAILS_SUCCESS:
      return {
        ...state,
        targetDetail: action.result,
      };
    case OUTREACH_FOLLOW_UP_REMINDER_SUCCESS:
      return {
        ...state,
        scheduleFollowup: action.result ? action.result : null,
      };
    case OUTREACH_SCRIPT_SUCCESS:
      return {
        ...state,
        talkingPoints: action.result.script,
      };
    case OUTREACH_SCHEDULE_FOLLOWUP_CALL_REMINDER_SUCCESS:
      return {
        ...state,
        followUpCallScheduled: true,
      };
    case OUTREACH_CANCEL_FOLLOWUP_CALL_REMINDER_SUCCESS:
      return {
        ...state,
        followUpCallCancelled: true,
      };
    case OUTREACH_REMOVE_FROM_LIST_SUCCESS:
      return {
        ...state,
        outreachRemoved: true,
      };
    case OUTREACH_REST_UPDATE_FLAGS_SUCCESS:
      return {
        ...state,
        outreachRemoved: false,
        followUpCallCancelled: false,
        followUpCallScheduled: false,
      };
    case OUTREACH_TARGET_ARCHIVE_STATUS_LIST_SUCCESS:
      return {
        ...state,
        outreachTargetArchiveStatusList: action.result,
      };
    case OUTREACH_TARGET_ARCHIVE_SUBSTATUS_LIST_SUCCESS:
      return {
        ...state,
        outreachTargetArchiveSubstatusList: action.result,
      };
    case OUTREACH_IVR_STATUS_LIST_SUCCESS:
      return {
        ...state,
        outreachIVRStatusList: action.result,
      };
    case OUTREACH_TARGET_ACTIVE_STATUS_LIST_SUCCESS:
      return {
        ...state,
        outreachActiveStatusList: action.result.active,
        outreachActiveFilterStatusList: action.result.all,
      };
    case OUTREACH_UPDATE_OUTREACH_TARGET_ACTIVE_SUCCESS:
      state.targetDetail.core_details.status = action.result.outreach_target_status;
      state.targetDetail.core_details.status_label = action.result.outreach_target_status_label;
      state.targetDetail.core_details.calendly_link = action.result.calendly_link;
      return {
        ...state,
      };
    case OUTREACH_TARGET_CALL_TX_STATUS_LIST_SUCCESS:
      return {
        ...state,
        outreachTargetCallTxStatusList: action.result.outreach_target_call_tx_statuses ? action.result.outreach_target_call_tx_statuses : [],
        outreachTargetCallTxStatusListRaw: action.result.outreach_target_call_tx_statuses_raw ? action.result.outreach_target_call_tx_statuses_raw : [],
      };
    case OUTREACH_TARGET_CALL_TX_SUBSTATUS_LIST_SUCCESS:
      return {
        ...state,
        outreachTargetCallTxSubstatusList: action.result,
      };
    case OUTREACH_ROOT_LEVEL_FILTERS_SUCCESS:
      return {
        ...state,
        outreachRootLevelFilters: action.result ? action.result : [],
      };
    case OUTREACH_CALL_QUEUE_LIST:
      return {
        ...state,
        outreachCallQueue: action.result ? action.result : [],
      };
    case OUTREACH_HISTORY_MODAL:
      return {
        ...state,
        outreachHistoryModal: action.result,
      };
    case OUTREACH_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        languageList: action.result ? action.result : [],
      };
    case OUTREACH_SUCCESS_GENERIC:
      return state;
    case UPDATE_ACTIVE_PAGE_NUMBER_AND_PAGE_SIZE:
      return {
        ...state,
        tabsManager:
          action.result && !isNaN(action.result.currentPage)
            ? {
                ...state.tabsManager,
                page: {
                  ...state.tabsManager.page,
                  [action.result.tab]: action.result.currentPage,
                },
              }
            : initialState.tabsManager,
      };
    case UPDATE_ACTIVE_PAGE_TAB:
      return {
        ...state,
        tabsManager:
          action.result && !isNaN(action.result.tab)
            ? {
                ...state.tabsManager,
                tab: action.result.tab,
              }
            : initialState.tabsManager,
      };
    default:
      return state;
  }
}

export function getOutreachMentorList(data) {
  return {
    types: [
      OUTREACH_LIST_LOAD_REQUESTED,
      OUTREACH_MENTORS_LIST_LOAD_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get(':9000/getmentoroutreach/list', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          page: data.page || 1,
        },
      }),
  };
}

export function getArchivedOutreachMentorList(data) {
  return {
    types: [
      OUTREACH_LIST_LOAD_REQUESTED,
      OUTREACH_ARCHIVED_MENTORS_LIST_LOAD_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get(':9000/getmentoroutreach/archived_list', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          page: data.page || 1,
        },
      }),
  };
}

// export function getOutreachMenteeList(data) {
//   return {
//     types: [OUTREACH_LOAD_REQUESTED, OUTREACH_MENTEES_SUCCESS, OUTREACH_FAIL],
//     promise: (client) => client.get('/getoutreach/list', {
//       params: {
//         org_id: data.org_id,
//         page: data.page || 1
//       }
//     })
//   };
// }

//Below function has been moved
export function getOutreachMenteeList(data) {
  return {
    types: [
      OUTREACH_LIST_LOAD_REQUESTED,
      OUTREACH_LIST_LOAD_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get(':9000/getoutreach/list', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          root_id: data.root_id,
          type_group: data.type_group,
          type_id: data.type_id,
          prioritized_ind: 0,
          page: data.page || 1,
        },
      }),
  };
}

//Below function has been moved
export function getOutreachMenteePrioritizedCallList(data) {
  return {
    types: [
      OUTREACH_PRIORITIZED_LIST_LOAD_REQUESTED,
      OUTREACH_PRIORITIZED_MENTEES_LIST_LOAD_SUCCESS,
      OUTREACH_PRIORITIZED_FAIL,
    ],
    promise: client =>
      client.get(':9000/getoutreach/list', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          root_id: data.root_id,
          type_group: data.type_group,
          type_id: data.type_id,
          prioritized_ind: 1,
          page: data.page || 1,
        },
      }),
  };
}


//Below function has been moved
export function getOutreachSearchResult(data) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get(':9000/getoutreach/search', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
          search_term: data.searchTerm,
        },
      }),
  };
}

//Below Function has been moved
export function getArchivedOutreachMenteeList(data) {
  return {
    types: [
      OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_REQUESTED,
      OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_SUCCESS,
      OUTREACH_ARCHIVED_MENTEES_LIST_LOAD_FAILED,
    ],
    promise: client =>
      client.get(':9000/getoutreach/archived_list', {
        params: {
          _st: 'Inquisit14',
          org_id: data.org_id,
        },
      }),
  };
}
// remove following when new outreach is live - Nikee 4/26/19
export function getOutreachTargetDetails(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_DETAILS_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/outreach_target_details', {
        params: {
          outreach_target_id: data.target_id,
          org_id: data.org_id,
        },
      }),
  };
}

export function getArchivedOutreachTargetDetails(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_DETAILS_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/outreach_archived_target_details', {
        params: {
          outreach_target_id: data.target_id,
          org_id: data.org_id,
        },
      }),
  };
}

export function getMentorOutreachTargetDetails(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_DETAILS_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getmentoroutreach/mentor_outreach_target_details', {
        params: {
          outreach_target_id: data.target_id,
          org_id: data.org_id,
        },
      }),
  };
}

export function getAddlMentorProspectDetails(mentorOutreachTargetId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/getmentoroutreach/get_addl_mentor_prospect_details', {
        params: {
          mentor_outreach_target_id: mentorOutreachTargetId
        },
      }),
  };
}

export function getMentorCallAttemptsBreakdownChartData(mentorOutreachTargetId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/getmentoroutreach/get_mentor_outreach_target_attempts_breakdown_chart_data', {
        params: {
          outreach_target_id: mentorOutreachTargetId
        },
      }),
  };
}

export function getMentorOutreachArchivedTargetDetails(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_DETAILS_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getmentoroutreach/mentor_outreach_archived_target_details', {
        params: {
          outreach_target_id: data.target_id,
          org_id: data.org_id,
        },
      }),
  };
}

// remove following function after new outreach profile
export function getOutreachTargetRecentAttempts(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_RECENT_ATTEMPTS_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/outreach_target_recent_attempts', {
        params: {
          outreach_target_id: data.target_id,
        },
      }),
  };
}

export function getMentorOutreachTargetRecentAttempts(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_RECENT_ATTEMPTS_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getmentoroutreach/mentor_outreach_target_recent_attempts', {
        params: {
          outreach_target_id: data.target_id,
        },
      }),
  };
}

export function resetRecentAttempts() {
  return {
    type: OUTREACH_TARGET_RECENT_ATTEMPTS_SUCCESS,
    result: [],
  };
}

export function setSearchFilters(filtersObj) {
  return {
    type: OUTREACH_FILTERS_SET,
    result: filtersObj,
  };
}
export function resetMentorOutreachList() {
  return {
    type: OUTREACH_RESET_MENTORS_SUCCESS,
    result: false,
  };
}


//Below function has been moved
export function resetMenteeOutreachList() {
  return {
    type: OUTREACH_RESET_MENTEES_SUCCESS,
    result: false,
  };
}

export function toggleOutreachHistoryModal(val) {
  return {
    type: OUTREACH_HISTORY_MODAL,
    result: val,
  };
}

export function getOutreachFollowUpReminder(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_FOLLOW_UP_REMINDER_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/followup_call_reminder_by_target_id', {
        params: {
          outreach_target_id: data.target_id,
        },
      }),
  };
}

export function getOutreachScript(data) {
  return {
    types: [OUTREACH_LOAD_REQUESTED, OUTREACH_SCRIPT_SUCCESS, OUTREACH_FAIL],
    promise: client =>
      client.get('/getoutreach/outreach_script', {
        params: {
          outreach_target_id: data.target_id,
          org_id: data.orgId,
          oc_name: data.oc_name,
        },
      }),
  };
}

export function getMentorOutreachScript(data) {
  return {
    types: [OUTREACH_LOAD_REQUESTED, OUTREACH_SCRIPT_SUCCESS, OUTREACH_FAIL],
    promise: client =>
      client.get('/getmentoroutreach/mentor_outreach_script', {
        params: {
          outreach_target_id: data.target_id,
          org_id: data.orgId,
          oc_name: data.oc_name,
        },
      }),
  };
}

export function getVoicemailsFromOutreachTarget(data) {
  return {
    types: [OUTREACH_LOAD_REQUESTED, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/getoutreach/get_voicemails_from_outreach_target', {
        params: {
          outreach_target_id: data.target_id,
        },
      }),
  };
}

export function getMentorOutreachVoicemailsFromOutreachTarget(data) {
  return {
    types: [OUTREACH_LOAD_REQUESTED, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get(
        '/getmentoroutreach/get_voicemails_from_mentor_outreach_target',
        {
          params: {
            outreach_target_id: data.target_id,
          },
        },
      ),
  };
}

//Below function has been mmoved
export function getMenteeTargetAccountLockStatus(outreachTargetId) {
  return {
    types: [OUTREACH_LOAD_REQUESTED, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/getoutreach/outreach_target_account_lock_status', {
        params: {
          outreach_target_id: outreachTargetId,
        },
      }),
  };
}

export function getMentorTargetAccountLockStatus(outreachTargetId) {
  return {
    types: [OUTREACH_LOAD_REQUESTED, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get(
        '/getmentoroutreach/mentor_outreach_target_account_lock_status',
        {
          params: {
            outreach_target_id: outreachTargetId,
          },
        },
      ),
  };
}

// Get - Common
export function getOutreachTargetArchiveStatuses(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_ARCHIVE_STATUS_LIST_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_archive_statuses', {
        params: {
          outreach_type: data.outreach_type,
        },
      }),
  };
}

export function getOutreachTargetArchiveSubstatuses(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_ARCHIVE_SUBSTATUS_LIST_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_archive_substatuses', {
        params: {
          root_id: data.root_id,
          outreach_type: data.outreach_type,
        },
      }),
  };
}

export function getOutreachTargetIvrStatuses(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_IVR_STATUS_LIST_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_ivr_statuses', {
        params: {
          outreach_type: data.outreach_type,
        },
      }),
  };
}

export function getOutreachTargetActiveStatuses(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_ACTIVE_STATUS_LIST_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_active_statuses', {
        params: {
          outreach_type: data.outreach_type,
          manually_apply_status_ind: data.manually_apply_status_ind,
        },
      }), 
      
  }; 
}
// remove when new outreach is live - Nikee 4/26/19
export function getOutreachTargetCallTxStatuses(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_CALL_TX_STATUS_LIST_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_call_tx_statuses', {
        params: {
          outreach_type: data.outreach_type,
        },
      }),
  };
}

export function getOutreachTargetCallTxSubstatuses(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_CALL_TX_SUBSTATUS_LIST_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_call_tx_substatuses', {
        params: {
          root_id: data.root_id,
          outreach_type: data.outreach_type,
          outreach_target_id: data.outreach_target_id,
        },
      }),
  };
}

// Set functions
export function unArchiveMentorOutreachTarget(data) {
  return {
    types: [OUTREACH_LOAD_REQUESTED, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get(
        '/setmentoroutreach/unarchive_mentor_outreach_target/' + data.ids,
        {},
      ),
  };
}

export function updateOutreachTargetVoicemailStatus(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_target_voicemail_status', {
        data: data,
      }),
  };
}

export function unArchiveOutreachTarget(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/unarchive_outreach_target', {
        data: data,
      }),
  };
}

export function removeMentorEnrollmentNote(outreachTargetId) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setmentoroutreach/update_outreach_target_enrollment_note', {
        data: {
          outreach_target_id: outreachTargetId,
        },
      }),
  };
}

export function updateMentorOutreachTarget(outreachTargetId, fields) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setmentoroutreach/update_outreach_target', {
        data: {
          outreach_target_id: outreachTargetId,
          fields: fields,
        },
      }),
  };
}

//Below function has been moved
export function updateMenteeOutreachTarget(outreachTargetId, fields) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_target', {
        data: {
          outreach_target_id: outreachTargetId,
          fields: fields,
        },
      }),
  };
}


//Below function has been moved
export function removeMenteeEnrollmentNote(outreachTargetId) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_target_enrollment_note', {
        data: {
          outreach_target_id: outreachTargetId,
        },
      }),
  };
}

export function updateOutreachCallStatus(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_tx', {
        data: data,
      }),
  };
}

export function updateOutreachTargetActiveStatus(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_UPDATE_OUTREACH_TARGET_ACTIVE_SUCCESS,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_target_active_status', {
        data: data,
      }),
  };
}

export function getOutreachRootLevelFilters(rootId) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_ROOT_LEVEL_FILTERS_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/get_outreach_sorting_filters', {
        params: {
          root_id: rootId,
        },
      }),
  };
}

export function getOutreachTargetCommunicationData(outreachTargetId, type) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_TARGET_COMMUNICATION_DATA_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_communication_data', {
        params: {
          outreach_target_id: outreachTargetId,
          type: type
        },
      }),
  };
}

export function getOutreachTargetPendingCallQueue(associatedOrgs) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_CALL_QUEUE_LIST, OUTREACH_FAIL],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_pending_call_queue', {
        params: {
          associated_orgs: associatedOrgs,
        },
      }),
  };
}

export function getOutreachTargetProfileVisitHistory(typeGroup, typeId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_profile_visit_history', {
        params: {
          type_group: typeGroup,
          type_id: typeId,
        },
      }),
  };
}

export function getOutreachIvrForecast(orgs) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/getoutreach/get_outreach_ivr_forecast', {
        params: {
          orgs: orgs,
        },
      }),
  };
}

// remove when new outreach is live - Nikee 4/26/19
export function getOutreachTargetTags(outreachTargetId) {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/getoutreach/get_outreach_target_tags', {
        params: {
          outreach_target_id: outreachTargetId,
        },
      }),
  };
}

export function getOutreachSystemTagsForFilter() {
  return {
    types: [OUTREACH_REQUEST_GENERIC, OUTREACH_SUCCESS_GENERIC, OUTREACH_FAIL],
    promise: client =>
      client.get('/getoutreach/get_outreach_system_tags_for_filter', {
        params: {},
      }),
  };
}

export function updateMentorOutreachTargetVoicemailStatus(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post(
        '/setmentoroutreach/update_outreach_target_voicemail_status',
        {
          data: data,
        },
      ),
  };
}

export function resetUpdateFlags() {
  return {
    type: OUTREACH_REST_UPDATE_FLAGS_SUCCESS,
    result: true,
  };
}

export function scheduleFollowupCallReminder(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_SCHEDULE_FOLLOWUP_CALL_REMINDER_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.post('/setoutreach/schedule_followup_call_reminder', {
        data: data,
      }),
  };
}

export function cancelFollowupCallReminder(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_CANCEL_FOLLOWUP_CALL_REMINDER_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.post('/setoutreach/cancel_scheduled_followup_call_reminder', {
        data: data,
      }),
  };
}

//Below function has been moved
export function setOutreachMenteesPhoneNumbers(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_PHONES_NUMBERS_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.post('/setoutreach/add_edit_phone_numbers', {
        data: data,
      }),
  };
}

export function setOutreachMentorsPhoneNumbers(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_PHONES_NUMBERS_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.post('/setmentoroutreach/add_edit_phone_numbers', {
        data: data,
      }),
  };
}

export function archiveOutreachTarget(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_REMOVE_FROM_LIST_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.post('/setoutreach/archive_outreach_target', {
        data: data,
      }),
  };
}

export function archiveMentorOutreachTarget(data) {
  return {
    types: [
      OUTREACH_LOAD_REQUESTED,
      OUTREACH_REMOVE_FROM_LIST_SUCCESS,
      OUTREACH_FAIL,
    ],
    promise: client =>
      client.post('/setmentoroutreach/archive_mentor_outreach_target', {
        data: data,
      }),
  };
}

export function updateMentorOutreachCallStatus(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setmentoroutreach/update_mentor_outreach_tx', {
        data: data,
      }),
  };
}
// remove the following call
export function updateOutreachTransaction(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_tx_by_id', {
        data: data,
      }),
  };
}

export function updateOutreachTargetCommunicationDataStatus(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_communication_data_status', {
        data: data,
      }),
  };
}

export function updateOutreachCallTxTypeInfo(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_tx_by_call_sid', {
        data: data,
      }),
  };
}

export function updateOutreachQueueCallStatus(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_call_queue_status', {
        data: data,
      }),
  };
}

export function upsertOutreachTargetProfileVisitHistory(outreachTargetId) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/upsert_outreach_target_profile_visit_history', {
        data: {
          outreach_target_id: outreachTargetId,
        },
      }),
  };
}

export function updateMentorOutreachTransaction(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setmentoroutreach/update_mentor_outreach_tx_by_id', {
        data: data,
      }),
  };
}

export function updateMentorOutreachTargetActiveStatus(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_UPDATE_OUTREACH_TARGET_ACTIVE_SUCCESS,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post(
        '/setmentoroutreach/update_mentor_outreach_target_active_status',
        {
          data: data,
        },
      ),
  };
}

//Below method has been moved
export function saveMenteeOutreachProspect(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/add_mentee_outreach_prospect', {
        data: data,
      }),
  };
}

export function saveMentorOutreachProspect(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/add_mentor_outreach_prospect', {
        data,
      }),
  };
}

export function getOutreachTargetStatusFieldValues(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/getenrolldata/get_outreach_target_status_field_values', {
        data,
      }),
  };
}

export function getLanguageFieldValues() {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_LANGUAGE_LIST_SUCCESS,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client => client.get('/getenrolldata/get_language_field_values'),
  };
}

export function updateOutreachTargetLanguage(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/add_edit_language', {
        data: data,
      }),
  };
}

export function addToTargetCommunicationLog(data) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/add_log_to_communication', {
        data: data,
      }),
  };
}

export function updateGeneralAvailability(
  outreachTargetId,
  appointmentId,
  datetime,
  endtime,
  days,
) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_general_availability', {
        data: {
          outreach_target_id: outreachTargetId,
          appointment_id: appointmentId,
          datetime: datetime,
          endtime: endtime,
          days: days,
        },
      }),
  };
}

export function validateIvrRecordingLabel(otId, id, labelInd) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/set_ivr_recording_label', {
        data: {
          outreach_target_id: otId,
          recording_id: id,
          label_ind: labelInd,
        },
      }),
  };
}

export function updateOutreachAccountLockStatus(id, status) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/update_outreach_account_access_lock', {
        data: {
          outreach_target_id: id,
          is_account_being_accessed_ind: status,
        },
      }),
  };
}

export function removeProspectFromCallQueue(outreachTargetId) {
  return {
    types: [
      OUTREACH_REQUEST_GENERIC,
      OUTREACH_SUCCESS_GENERIC,
      OUTREACH_FAIL_GENERIC,
    ],
    promise: client =>
      client.post('/setoutreach/remove_from_call_queue', {
        data: {
          outreach_target_id: outreachTargetId,
        },
      }),
  };
}

export function updateActivePageNumberAndSizePerPage(data) {
  return {
    type: UPDATE_ACTIVE_PAGE_NUMBER_AND_PAGE_SIZE,
    result: data,
  };
}

export function updateActivePageTab(data) {
  return {
    type: UPDATE_ACTIVE_PAGE_TAB,
    result: data,
  };
}
