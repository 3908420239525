const GENERIC_REQUEST = 'inquisit-app/generic/REQUEST';

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}


export function getSearchResults(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_REQUEST, GENERIC_REQUEST],
    promise: client =>
      client.get('/admin/general/get_search_results', {
        params: data
      }),
  };
}

export function getSystemLegacyNewMode(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_REQUEST, GENERIC_REQUEST],
    promise: client =>
      client.get('/getsystemdata/get_system_legacy_new_mode', {
        params: data
      }),
  };
}

export function setSystemLegacyNewMode(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_REQUEST, GENERIC_REQUEST],
    promise: client =>
      client.post('/setsystemdata/set_system_legacy_new_mode', {
        params: data
      }),
  };
}