const CMS_ISSUE_GUIDER_REQUEST_GENERIC = 'inquisit-app/CMS/CMS_ISSUE_GUIDER_REQUEST_GENERIC';
const CMS_ISSUE_GUIDER_SUCCESS_GENERIC = 'inquisit-app/CMS/CMS_ISSUE_GUIDER_SUCCESS_GENERIC';
const CMS_ISSUE_GUIDER_FAIL_GENERIC = 'inquisit-app/CMS/CMS_ISSUE_GUIDER_FAIL_GENERIC';
const AREA_FLOW = 'inquisit-app/CMS/AREA_FLOW';

const GENERIC_TYPES = [CMS_ISSUE_GUIDER_REQUEST_GENERIC, CMS_ISSUE_GUIDER_SUCCESS_GENERIC, CMS_ISSUE_GUIDER_FAIL_GENERIC];
const initialState = {
  copiedIssueAreaFlow: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AREA_FLOW:
      return {
        ...state,
        copiedIssueAreaFlow: action.result.data
      };
    default:
      return state;
  }
}

// GET

export function getIssueAreas() {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/get_issue_area')
  };
}


export function getIssueAreaMapping(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/area/get_issue_area_mapping', {
      params: data
    })
  };
}

export function getAreaIssuesWithGuider(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/area/get_area_issues_with_guiders', {
      params: data
    })
  };
}

export function getIssueAreaCombinations(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.get('getcmsdata/area/get_issue_area_combinations', {
      params: data
    })
  };
}

// POST

export function copyIssueAreaFlow(data) {
  return {
    type: AREA_FLOW,
    result: {
      data
    }
  };
}

export function processAndStoreIssueAreaMapping(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/area/process_and_store_issue_area_mapping', {
      data: data
    })
  };
}

export function publishAreaGuiderVersion(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/area/publish_area_guider_version', {
      data: data
    })
  };
}

export function insertIssueAreaConditions(data) {
  return {
    types: GENERIC_TYPES,
    promise: (client) => client.post('setcmsdata/area/insert_issue_area_x_conditions', {
      data: data
    })
  };
}
