const PMU_CREATE_MENTOR_REQUEST = 'inquisit-app/PMU/PMU_CREATE_MENTOR_REQUEST';
const PMU_CREATE_MENTOR_SUCCESS = 'inquisit-app/PMU/PMU_CREATE_MENTOR_SUCCESS';
const PMU_CREATE_MENTOR_FAIL = 'inquisit-app/PMU/PMU_CREATE_MENTOR_FAIL';

const PMU_MENTOR_ENROLLMENT_REQUEST = 'inquisit-app/PMU/PMU_MENTOR_ENROLLMENT_REQUEST';
const PMU_MENTOR_ENROLLMENT_SUCCESS = 'inquisit-app/PMU/PMU_MENTOR_ENROLLMENT_SUCCESS';
const PMU_MENTOR_ENROLLMENT_FAIL = 'inquisit-app/PMU/PMU_MENTOR_ENROLLMENT_FAIL';

const PMU_GENERIC_REQUEST = 'inquisit-app/PMU/PMU_GENERIC_REQUEST';
const PMU_GENERIC_SUCCESS = 'inquisit-app/PMU/PMU_GENERIC_SUCCESS';
const PMU_GENERIC_FAIL = 'inquisit-app/PMU/PMU_GENERIC_FAIL';

const GENERIC = [PMU_GENERIC_REQUEST, PMU_GENERIC_SUCCESS, PMU_GENERIC_FAIL];

const PMU_LOGIN = 'inquisit-app/auth/PMU_LOGIN';
const PMU_LOGIN_SUCCESS = 'inquisit-app/auth/PMU_LOGIN_SUCCESS';
const PMU_LOGIN_FAIL = 'inquisit-app/auth/PMU_LOGIN_FAIL';

const LOGIN = [PMU_LOGIN, PMU_LOGIN_SUCCESS, PMU_LOGIN_FAIL];

const initialState = {
  loading: false,
  mentorEnrolled: true,
  error: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PMU_CREATE_MENTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PMU_CREATE_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PMU_CREATE_MENTOR_FAIL:
      return {
        ...state,
        loading: false,
      };
    case PMU_MENTOR_ENROLLMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PMU_MENTOR_ENROLLMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PMU_MENTOR_ENROLLMENT_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

/*
 * GET requests
 */

export function getPmuEnrollmentStatus({ mentor_id, outreach_target_id }) {
  return {
    types: [PMU_MENTOR_ENROLLMENT_REQUEST, PMU_MENTOR_ENROLLMENT_SUCCESS, PMU_MENTOR_ENROLLMENT_FAIL],
    promise: client =>
      client.get('/get/pmu/enrollment_status', {
        params: { mentor_id, outreach_target_id },
      }),
  };
}

export function getMentorEnrolledCourses({ mentor_id }) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/get/pmu/get_mentor_enrolled_courses', {
        params: { mentor_id }
      }),
  };
}

export function getMentorPMUAdminTasks({ mentor_id }) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/get/pmu/get_mentor_pmu_admin_tasks', {
        params: { mentor_id }
      }),
  };
}

export function getPMUDashboardStats() {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/get/pmu/get_pmu_dashboard_stats', {
        params: {}
      }),
  };
}

export function getPMUDashboardStatList(data) {
  return {
    types: GENERIC,
    promise: client =>
      client.get('/get/pmu/get_pmu_dashboard_course_user_list', {
        params: data
      }),
  };
}

export function getCourseDeadline(pmu_user_id, pmu_course_id) {
  return {
    types: GENERIC,
    promise: (client) => client.get('/get/pmu/get_mentor_course_deadline', {
      params: {
        pmu_user_id: pmu_user_id,
        course_id: pmu_course_id
      }
    }),
  };
}

export function getPmuAutoLoginLink(pmu_user_id, redirect_link){
  //redirect link should only contain link path -- "pmu.mentor1to1.org/course/2918" SHOULD BE "/courses/2918"
  return{
    types: LOGIN,
    promise: (client) => client.get('/get/pmu/auto_login_link', {
      params: {
        pmu_user_id: pmu_user_id,
        redirect_link: redirect_link
      }
    })
  };
}


/*
 * POST requests
 */

export function addMentorToPmuPlatform({ mentor_id, outreach_target_id, auto_enroll_ind }) {
  return {
    types: [PMU_CREATE_MENTOR_REQUEST, PMU_CREATE_MENTOR_SUCCESS, PMU_CREATE_MENTOR_FAIL],
    promise: client =>
      client.post('/set/pmu/add_mentor', {
        data: { mentor_id, auto_enroll_ind, outreach_target_id },
      }),
  };
}

export function enrollUserInACourse({ user_type_group, user_type_id, pmu_course_id }) {
  return {
    types: [PMU_CREATE_MENTOR_REQUEST, PMU_CREATE_MENTOR_SUCCESS, PMU_CREATE_MENTOR_FAIL],
    promise: client =>
      client.post('/set/pmu/assign_course_to_user', {
        data: { assign_to_user_type_group: user_type_group , assign_to_user_type_id: user_type_id, pmu_course_id: pmu_course_id },
      }),
  };
}

export function sendPendingCourseReminderEmail(mentor_id) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/set/pmu/send_mentor_course_in_progress_reminder', {
        data: { mentor_id },
      }),
  };
}

export function updateMentorPmuUserId(mentor_id, pmu_user_id) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/set/pmu/update_mentor_pmu_user_id', {
        data: { mentor_id, pmu_user_id },
      }),
  };
}

export function bulkAssignPmuCourses(courses, userIds, userType) {
  return {
    types: GENERIC,
    promise: client =>
      client.post('/set/pmu/bulk_assign_pmu_courses', {
        data: { 
          courses: courses,
          user_ids: userIds,
          user_type: userType
        },
      }),
  };
}

export function updateCourseDeadline(pmu_user_id, pmu_course_id, date){
  return{
    types: GENERIC,
    promise: client => client.post('/set/pmu/update_mentor_course_deadline', {
      data: {
        pmu_user_id: pmu_user_id,
        course_id: pmu_course_id,
        deadline: date
      }
    })
  };
}

export function unenrollMentorFromCourse(pmu_user_id, pmu_course_id){
    return{
    types: GENERIC,
    promise: client => client.post('set/pmu/unenroll_course', {
      data: {
        pmu_user_id: pmu_user_id,
        course_id: pmu_course_id,
      }
    })
  };
}